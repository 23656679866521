import { memo, useCallback, useEffect, useState } from "react";

import { IDateRange, ITourFare } from "@interfaces";
import { TourFareComponent } from "@components";
import { AppDispatch, getFarePrice } from "@redux";
import { useDispatch } from "react-redux";

interface IPropsTourFare {
    tourFare: ITourFare;
    tourFares: ITourFare[];
    tourId: number | string;
}

export const TourFareModule = memo((props: IPropsTourFare) => {
    //props
    const { tourFare } = props;
    //hook
    const dispatch = useDispatch<AppDispatch>();
    //page state
    const [date, setDate] = useState<IDateRange>({
        from: null,
        to: null,
    });
    const [bookingTime, setBookingTime] = useState<string | undefined>();

    useEffect(() => {
        dispatch(getFarePrice(tourFare.productPricesDetailsId, tourFare.fareName));
    }, []);

    useEffect(() => {
        setBookingTime(tourFare.bookingTimeId?.toString());
    }, [tourFare.bookingTimeId]);

    const handleChangeTime = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setBookingTime(event.target.value);
    }, []);

    const changeDateFrom = useCallback(
        (dateCheck: Date) => {
            setDate({ to: date.to, from: dateCheck });
        },
        [date]
    );

    const changeDateTo = useCallback(
        (dateCheck: Date) => {
            setDate({ from: date.from, to: dateCheck });
        },
        [date]
    );

    return (
        <TourFareComponent
            tourFare={tourFare}
            date={date}
            changeDateFrom={changeDateFrom}
            changeDateTo={changeDateTo}
            bookingTime={bookingTime}
            handleChangeTime={handleChangeTime}
            tourId={props.tourId}
        />
    );
});
