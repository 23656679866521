import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { LoadingCommon } from "@components";
import {
    getListBooking,
    openToast,
    selectAgent,
    setCurrentStep,
    setStep,
    useAppDispatch,
    useAppSelector,
} from "@redux";
import { CartConstants, DURATION_TIME } from "@constants";
import { lsBookingReference, ssOrder } from "@utilities";
import { PATH_CART, PATH_HOME, PATH_PAYMENT_FAIL } from "@routes";

export const OrderSuccess = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { agentInfo } = useAppSelector(selectAgent);

    useEffect(() => {
        const handleSuccess = () => {
            if (window.location.search) {
                const params = window.location.search.substring(1);
                const paramsObj = queryString.parse(params);

                let message = `Payment failed. Please contact support@freelancetravel.com for manual payment.`;

                if (paramsObj.status === "1") {
                    dispatch(setCurrentStep(CartConstants.ORDER_SUCCESS));
                    dispatch(setStep(CartConstants.ORDER_SUCCESS));
                    dispatch(getListBooking());

                    const orderInfo = paramsObj.cmsgw_OrderInfo as string | undefined;
                    if (orderInfo) {
                        const index = orderInfo.indexOf("API_");
                        if (index !== -1) {
                            const bookingReference = orderInfo.slice(index + 4);
                            lsBookingReference.setItem(bookingReference);
                            ssOrder.setItem(paramsObj);
                        }
                    }
                } else if (paramsObj.error) {
                    if (agentInfo) {
                        dispatch(setCurrentStep(CartConstants.PAYMENT));
                        dispatch(setStep(CartConstants.PAYMENT));
                        message = paramsObj.error as string;
                        dispatch(
                            openToast({
                                type: "error",
                                message,
                                autoHideDuration: DURATION_TIME,
                            })
                        );
                    } else {
                        navigate(PATH_PAYMENT_FAIL);
                    }
                } else {
                    dispatch(setCurrentStep(CartConstants.PAYMENT));
                    dispatch(setStep(CartConstants.PAYMENT));
                    dispatch(
                        openToast({
                            type: "error",
                            message,
                            autoHideDuration: DURATION_TIME,
                        })
                    );
                }

                navigate(PATH_CART);
            } else {
                navigate(PATH_HOME);
            }
        };

        handleSuccess();
    }, [dispatch, navigate, agentInfo]);

    return <LoadingCommon />;
};
