import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!").email("Email is invalid"),
    password: Yup.string().required("This field is required!"),
});

export const agentloginSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!").email("Email is invalid"),
    password: Yup.string().required("This field is required!"),
});

export const loginDemoSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!").email("Email is invalid"),
    password: Yup.string().required("This field is required!"),

});

export const forgotPassSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!").email("Email is invalid")
});

export const signupEmailSchema =Yup.object().shape({
    email: Yup.string().required("This field is required!").email("Email is invalid")
});

export const forgotPassEmailSchema =Yup.object().shape({
    email: Yup.string().required("This field is required!").email("Email is invalid")
});

export const signupPasswordSchema =Yup.object().shape({
    password: Yup.string().required("This field is required!"),
    ReEnterPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required("This field is required!"),
});


export const resetPassSchema = Yup.object().shape({
    password: Yup.string().required("This field is required!"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required("This field is required!"),
});