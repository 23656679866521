import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';

import { breakpoint } from '@utilities';

interface IProps {
    id?: string;
    isChecked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckBoxComponent = (props: IProps) => {
    const { id, isChecked, onChange } = props;

    return (
        <Container>
            <Checkbox
                checked={isChecked}
                onChange={onChange}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                id={id}
            />
        </Container>
    );
};

const Container = styled.div`
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: ${p => p.theme.colors.main};
    }
    
    .PrivateSwitchBase-root-1 {
        padding: 5px;

        ${breakpoint.breakLarge`
            padding: 9px;
        `}
    }
    
    .MuiSvgIcon-root {
        width: 1em;
        height: 1em;
    }
`;