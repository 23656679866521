import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router";

import { ISavedCart } from "@interfaces";
import { useWindowDimensions } from "@utilities";
import { SavedCartWebComponent, SavedCartMobileComponent } from "@components";
import {
    selectBooking,
    setShowPopup,
    useAppSelector,
    replaceCart,
    updateCurrentCartId,
    setStep,
    removeSavedCart,
    useAppDispatch,
} from "@redux";
import { bookingApi } from "@api";
import { PATH_CART } from "@routes";
import { CartConstants } from "@constants";

interface IProps {
    data: ISavedCart;
    isList?: boolean;
}

export const SavedCartItemModule = (props: IProps) => {
    //hook
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    //props
    const { data, isList } = props;
    //redux state
    const paymentMethod = useAppSelector(selectBooking).paymentMethod;
    //page state
    const [isShowPopUp, setIsShowPopUp] = useState<boolean>(false);
    //page variable
    const dateFormat = moment(new Date(data.date)).format("DD/MM/YY");
    const leaderIndex = data.cart.customers.findIndex((customer) => customer.isLeader);
    const leader = leaderIndex >= 0 && data.cart.customers[leaderIndex];
    const name = leader ? (leader.firstName + " " + leader.lastName).trim() : "New Cart";
    const { width } = useWindowDimensions();

    const togglePopUp = () => {
        setIsShowPopUp(!isShowPopUp);
        dispatch(setShowPopup(!isShowPopUp));
    };

    // WHY: create new payment link will interrupt old created payment link
    const destroyOldPaymentLink = async () => {
        const returnUrl = paymentMethod?.paymentReturnUrl;
        const bookingReference = data.cart.bookingReference;
        if (returnUrl && bookingReference) {
            await bookingApi.getPaymentGatewayUrl(bookingReference, "0.01", "http://" + returnUrl);
        }
    };

    const handleEdit = async () => {
        await destroyOldPaymentLink();

        togglePopUp();
        dispatch(replaceCart(data.cart));
        dispatch(updateCurrentCartId(data.id));

        if (data.cart.currentStep === CartConstants.CHECKOUT) {
            dispatch(setStep(CartConstants.CHECKOUT));
        } else {
            dispatch(setStep(CartConstants.CART));
        }
        navigate(PATH_CART);
    };

    const handleRemove = async () => {
        //Remove in savedCart redux
        dispatch(removeSavedCart(data.id));
        await destroyOldPaymentLink();
        togglePopUp();
    };

    if (width >= 767) {
        return (
            <SavedCartWebComponent
                id={data.id}
                isList={isList}
                dateFormat={dateFormat}
                name={name}
                cart={data.cart}
                togglePopUp={togglePopUp}
                handleEdit={handleEdit}
                handleRemove={handleRemove}
                isShowPopUp={isShowPopUp}
            />
        );
    } else {
        return (
            <SavedCartMobileComponent
                id={data.id}
                isList={isList}
                dateFormat={dateFormat}
                name={name}
                cart={data.cart}
                togglePopUp={togglePopUp}
                handleEdit={handleEdit}
                handleRemove={handleRemove}
                isShowPopUp={isShowPopUp}
            />
        );
    }
};
