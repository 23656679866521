import { useDispatch } from "react-redux";
import { InputBase, Select, SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ChangeEvent, memo, useState } from "react";
// import debounce from "lodash/debounce";

import { StyledError } from "@components";
import { AppDispatch, saveAnswersCartItem } from "@redux";
import { OptionalField } from "@interfaces";

interface IPropsCartItem {
    optionalField: OptionalField;
    itemId: string | number | null;
}
interface ISwitchInputComponent {
    optionalField: OptionalField;
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChangeDate: (date: Date | null) => void;
    onChangeSelect: (
        event: SelectChangeEvent<string>, 
    ) => void;
}

export const SwitchInputComponent = memo((props: ISwitchInputComponent) => {
    const { optionalField, onChange, onChangeSelect } = props;

    switch (optionalField.type) {
        case "text":
            return (
                <InputBase
                    onChange={onChange}
                    value={optionalField.value ? optionalField.value : ""}
                />
            );
        case "select":
            return (
                <Select
                    labelId="label"
                    value={optionalField.value ? optionalField.value : optionalField.options[0]}
                    IconComponent={KeyboardArrowDownIcon}
                    onChange={onChangeSelect}
                >
                    {optionalField.options.map((option: string, index: number) => {
                        return (
                            <MenuItem value={option} key={index}>
                                {option}
                            </MenuItem>
                        );
                    })}
                </Select>
            );
        case "number": {
            return (
                <InputBase
                    onChange={onChange}
                    type="number"
                    inputProps={{ inputProps: { min: 0 } }}
                    value={optionalField.value ? optionalField.value : 0}
                />
            );
        }
        // case "date":
        //     return (
        //         <DatePickerCommon
        //             value={optionalField.value ? optionalField.value : null}
        //             onChange={onChangeDate}
        //         />
        //     );
        default:
            return <> </>;
    }
});

export const OptionalFieldModule = memo((props: IPropsCartItem) => {
    const { optionalField, itemId } = props;
    const dispatch = useDispatch<AppDispatch>();
    const [error, setError] = useState<string>("");

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const type = e.target.type;

        if (!value && optionalField.mandatory) {
            setError("This field is required");
        } else {
            setError("");
        }

        if (type === "number") {
            if (+value === -1) {
                dispatch(saveAnswersCartItem({ itemId, optionalFieldId: optionalField.optionalFieldId, value: "0" }));
            } else if (+value >= 0) {
                dispatch(saveAnswersCartItem({ itemId, optionalFieldId: optionalField.optionalFieldId, value }));
            }
        } else {
            dispatch(saveAnswersCartItem({ itemId, optionalFieldId: optionalField.optionalFieldId, value }));
        }
    };

    const handleChangeSelect = (e: SelectChangeEvent<string>) => {
        const value = e.target.value;

        if (!value && optionalField.mandatory) {
            setError("This field is required");
        } else {
            setError("");
        }

        dispatch(saveAnswersCartItem({ itemId, optionalFieldId: optionalField.optionalFieldId, value }));
    };

    const handleDateChange = (date: Date | null) => {
        if (!date && optionalField.mandatory) {
            setError("This field is required");
        } else {
            setError("");
        }

        dispatch(saveAnswersCartItem({ itemId, optionalFieldId: optionalField.optionalFieldId, value: date }));
    };

    return (
        <>
            <SwitchInputComponent
                optionalField={optionalField}
                onChange={handleChange}
                onChangeDate={handleDateChange}
                onChangeSelect={handleChangeSelect}
            />
            <StyledError>{error}</StyledError>
        </>
    );
});
