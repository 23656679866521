import { Formik } from "formik";
import { unwrapResult } from "@reduxjs/toolkit";
import { LoginFormComponent, StyledLink, LoginLogo } from "@components";
import { ILogin } from "@interfaces";
import { loginSchema } from "@validations";
import { AppDispatch, loginV2, toggleLoading } from "@redux";
import { lsEmail } from "@utilities";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SuccessToast } from "src/Components/toasts/SuccessToast";
import { ErrorToast } from "src/Components/toasts/ErrorToast";

export const Login = () => {
    const dispatch = useDispatch<AppDispatch>();

    const initialValues: ILogin = {
        email: process.env.REACT_APP_EMAIL || "",
        password: process.env.REACT_APP_PASSWORD || "",
    };

    return (
        <>
            <LoginLogo src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg" />
            <Formik
                initialValues={initialValues}
                validationSchema={loginSchema}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        dispatch(toggleLoading(true));
                        const res = await dispatch(loginV2(values));
                        unwrapResult(res);
                        SuccessToast({ Message: "Login Successfull" });
                        //@ts-ignore
                        if (!res.payload.data.accessToken) {
                            setErrors({
                                password: "Please check username and password are correct",
                            });
                        } else {
                            lsEmail.setItem(values.email);
                        }
                    } catch (error: any) {
                        if (error.data?.password?.[0]) {
                            setErrors({ password: error.data.password[0] });
                        } else if (error.data?.email?.[0]) {
                            setErrors({ email: error.data.email[0] });
                        } else {
                            ErrorToast({
                                Message: error.message || "An unexpected error occurred",
                            });
                        }
                    } finally {
                        setSubmitting(false);
                        dispatch(toggleLoading(false));
                    }
                }}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    errors,
                    touched,
                }) => {
                    return (
                        <div style={{ width: "100%" }}>
                            <LoginFormComponent
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSubmit={handleSubmit}
                                isSubmitting={isSubmitting}
                                errors={errors}
                                touched={touched}
                            />
                        </div>
                    );
                }}
            </Formik>
            <StyledLink>
                Don't have an account? <NavLink to="/signup"> Sign up</NavLink>, to start selling
                today.
            </StyledLink>
        </>
    );
};
