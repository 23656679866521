import { InputCommon, StyledButton, StyledForm } from "@components";
import { ISignUpPassword } from "@interfaces";
import { IHandleChange, IHandleBlur, IHandleSubmit } from "@interfaces";
import { Divider } from "@mui/material";
import styled from "styled-components";
import GoogleLoginButton from "../GoogleLoginButton";
import CustomAppleSignIn from "../AppleLoginButton";

interface SignUpPasswordProps {
    values: ISignUpPassword;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    isSubmitting: boolean;
}

export const SignUpPasswordComponent = (props: SignUpPasswordProps) => {
    const { values, handleChange, handleBlur, handleSubmit, isSubmitting } = props;

    return (
        <StyledForm onSubmit={handleSubmit}>
            <StyledWrapper>
                <h2 className="heading">Sign Up</h2>
            </StyledWrapper>
            <InputCommon
                name="password"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.password}
                label="Password"
                type="password"
            />
            <InputCommon
                name="ReEnterPassword"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.ReEnterPassword}
                label="Re-enter Password"
                type="password"
            />
            <StyledButton
                borderRadius="4px"
                color={["#436CB2 0%", "#28B7C3 100%"]}
                disabled={isSubmitting}
                type="submit"
            >
                Sign Up
            </StyledButton>
            <Divider
                style={{
                    color: "#898D8F",
                    marginTop: "22px",
                    marginBottom: "2px",
                    fontWeight: "bold",
                    fontSize: "12px",
                }}
            >
                OR
            </Divider>
            <CustomAppleSignIn />
            <GoogleLoginButton />
            <StyledButton borderRadius="4px" disabled={isSubmitting} type="button" color="#0078FF">
                <img src="/images/Facebook.svg" alt="Facebook Logo" />
                Sign Up with Facebook
            </StyledButton>
        </StyledForm>
    );
};

const StyledWrapper = styled(StyledForm)`
    .heading {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
    }
`;
