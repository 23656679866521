import { memo } from "react";
import styled from "styled-components";

import { IHandleChange, IHandleBlur, IHandleSubmit, IAgentInfoV2 } from "@interfaces";
import { InputCommon, StyledButton } from "@components";
import { breakpoint } from "@utilities";

interface IProps {
    values: IAgentInfoV2;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    isSubmitting: boolean;
}
export const LinkAgentComponent = memo((props: IProps) => {
    const { values, handleChange, handleSubmit, handleBlur, isSubmitting } = props;
    return (
        <Container onSubmit={handleSubmit}>
            <p className="text-tag">Login with your TDMS Credentials</p>
            <InputCommon
                name="username"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.username}
                label="TDMS username"
                required
                type="text"
            />
            <InputCommon
                name="password"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.password}
                label="TDMS password"
                required
                type="password"
            />
            <StyledButton
                color={["#436CB2 0%", "#28B7C3 100%"]}
                disabled={isSubmitting}
                className="submit_account"
                type="submit"
                borderRadius="4px"
            >
                Link
            </StyledButton>
        </Container>
    );
});

const Container = styled.form`
    .Wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .col_48 {
            width: 48%;
        }
        ${breakpoint.breakOnlyMobile`
        .col_48.m_100 {
            width: 100%;
         }
        `};
    }
    .submit_account {
        width: 32.5%;
        margin: auto;
        margin-top: 50px;
        ${breakpoint.breakOnlyMobile`
        width: 100%;
        margin-top: 30px;
        `};
    }
    .MuiFormControl-root {
        height: 65px;
        margin: 0 !important;
    }
    .title__account.form {
        margin-top: 36px;
        margin-bottom: 12;
    }
    .text-tag {
        font-weight: 600;
        margin-bottom: 30px;
        margin-top: 20px;
    }
`;
