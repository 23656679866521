import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import {
    BreadcrumbsCommon,
    ClipLoaderCommon,
    MenuAccountComponent,
    StyledSavedCartPage,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_HOME, PATH_SAVED_CART } from "@routes";
import { getCustomerOrder, selectSavedCarts, useAppDispatch, useAppSelector } from "@redux";
import { ISavedCart, IPagination } from "@interfaces";
import { useWindowDimensions } from "@utilities";
import { PaginationModule, SavedCartItemModule } from "@module";
import { PAGINATION } from "@constants";

export const SavedCart = () => {
    //hooks
    const dispatch = useAppDispatch();
    //redux state
    const { carts, isLoading } = useAppSelector(selectSavedCarts);
    //page state
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [keySearch, setKeySearch] = useState<string>("");
    const [paginationCarts, setPaginationCarts] = useState<ISavedCart[]>(
        carts.slice(0, PAGINATION)
    ); // WHY: for pagination
    //page variable
    const breadcrumbsModel = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Saved Quotes",
            link: PATH_SAVED_CART,
        },
    ];
    const { width } = useWindowDimensions();

    // lay Gap = 10 carts đầu tiền để hiên thị

    const listPagePoints: IPagination[] = [];
    const pages = Math.ceil(carts.length / PAGINATION);
    for (let i = 1; i <= pages; i++) {
        listPagePoints.push({ id: i });
    }
    useEffect(() => {
        dispatch(getCustomerOrder());
    }, []);

    // WHAT: paginationCarts hiện tại nếu bằng 0 thì trả về paginationCarts page trc nó và currentPage === 1 thì để màn trống
    useEffect(() => {
        if (paginationCarts.length === 0 && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }, [paginationCarts]);

    const handleSetCurrentPage = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleLoadMoreMobile = () => {
        const input = keySearch.trim().toLocaleUpperCase();
        // nếu thanh search không có giá trị mới cho load more
        if (input !== "") return;
        if (paginationCarts.length >= carts.length) return;
        if (carts.length - paginationCarts.length < PAGINATION) {
            setPaginationCarts([...paginationCarts, ...carts.slice(paginationCarts.length)]);
        } else {
            setPaginationCarts([
                ...paginationCarts,
                ...carts.slice(paginationCarts.length, paginationCarts.length + PAGINATION),
            ]);
        }
    };

    const handleChangeKeySearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeySearch(event.target.value);
    };

    // logic khi search
    useEffect(() => {
        const NEW_CART = "NEW CART";
        const input = keySearch.trim().toUpperCase();
        const search = carts.filter((cart: ISavedCart) => {
            const customerNames = cart.cart.customers.map((customer) =>
                `${customer?.firstName} ${customer?.lastName}`.toUpperCase()
            );
            const customerEmails = cart.cart.customers.map((customer) =>
                customer?.email?.toUpperCase()
            );
            if (customerNames.length === 0 || customerEmails.length === 0) {
                return NEW_CART.includes(input);
            } else {
                return (
                    customerNames.some((name) => name.includes(input)) ||
                    customerEmails.some((email) => email.includes(input))
                );
            }
        });
        if (width < 768) {
            if (input === "") {
                setPaginationCarts(carts);
            } else {
                setPaginationCarts(search);
            }
        } else {
            // xử lý khi phân trang
            if (input === "") {
                const startPoint = (currentPage - 1) * PAGINATION;
                const endPoint = currentPage * PAGINATION;
                setPaginationCarts(carts.slice(startPoint, endPoint));
            } else {
                const startPoint = (currentPage - 1) * PAGINATION;
                const endPoint = currentPage * PAGINATION;
                setPaginationCarts(search.slice(startPoint, endPoint));
            }
        }
    }, [keySearch, currentPage, carts]);

    return (
        <AppModuleLayout>
            {isLoading ? (
                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
            ) : (
                <StyledSavedCartPage>
                    <div className="wrapper">
                        <div className="breadCrumbs">
                            <BreadcrumbsCommon data={breadcrumbsModel} />
                        </div>
                        <div className="subWrapper">
                            <div className="searchBox">
                                <div className="icon">
                                    <img src="/images/fi_search.png" alt="" />
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={keySearch}
                                    onChange={handleChangeKeySearch}
                                />
                            </div>
                            <div className="menu">
                                <MenuAccountComponent />
                            </div>
                            <div className="listCarts">
                                {width >= 767 && <p className="heading">Quotes</p>}
                                <div className="searchBox">
                                    <div className="icon">
                                        <img src="/images/fi_search.png" alt="" />
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={keySearch}
                                        onChange={handleChangeKeySearch}
                                    />
                                </div>
                                <div className="row">
                                    {width >= 767 ? (
                                        paginationCarts.map((item) => {
                                            return (
                                                <div className="col c-12 m-12 l-12" key={item.id}>
                                                    <SavedCartItemModule data={item} isList />
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <InfiniteScroll
                                            dataLength={carts.length}
                                            next={handleLoadMoreMobile}
                                            hasMore={true}
                                            loader={<></>}
                                        >
                                            {paginationCarts.map((item) => {
                                                return (
                                                    <div className="col c-12 m-6 l-6" key={item.id}>
                                                        <SavedCartItemModule data={item} isList />
                                                    </div>
                                                );
                                            })}
                                        </InfiniteScroll>
                                    )}
                                </div>
                            </div>
                        </div>

                        {carts.length / PAGINATION > 1 && width >= 767 && (
                            <div className="pagination">
                                <PaginationModule
                                    onSetCurrentPage={handleSetCurrentPage}
                                    listPagePoints={listPagePoints}
                                    currentPage={currentPage}
                                />
                            </div>
                        )}
                    </div>
                </StyledSavedCartPage>
            )}
        </AppModuleLayout>
    );
};
