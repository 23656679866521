import { ICancelRequest } from "./../Interfaces/booking";
import { IFavoriteTour } from "./../Interfaces/tours";
import { ISendLogEmail } from "./../Interfaces/phpHelper";
import axios from "axios";
import queryString from 'query-string';

import { IBooking, IAccountInfo, ISavedCart, IShareBookingRequest } from "@interfaces";
import { PHP_HELPER, PHP_TOKEN } from "@constants";
import { store } from "@redux";
import { lsEmail } from "@utilities";

const axiosClient = axios.create({
    baseURL: PHP_HELPER,

    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        token_access: PHP_TOKEN,
    },

    paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use((config) => {
    const token = store.getState().auth.auth?.access_token;

    // config.headers.Authorization = `Bearer ${token}`;
    config.headers.fttoken = token;
    config.headers.fturl = process.env.REACT_APP_API_URL;

    return config;
});

axiosClient.interceptors.response.use(
    (response) => {
        if (response && response.data) {
            return response.data;
        }
        return response;
    },

    (error: any) => {
        throw error;
    }
);

export const phpHelper = {
    booking: (data: IBooking) => {
        axiosClient.post("connect-payment", data);
    },
    saveAccount: async (json: IAccountInfo) => {
        const email = lsEmail.getItem();
        const body = {
            email,
            json,
        };
        await axiosClient.post("save-account", body);
    },
    getAccount: async () => {
        const email = lsEmail.getItem();
        return await axiosClient.get(`detail-account/${email}`);
    },
    saveQuotes: (json: ISavedCart[]) => {
        const email = lsEmail.getItem();
        const body = {
            email,
            json,
        };
        axiosClient.post("save-quote", body);
    },
    getQuotes: () => {
        const email = lsEmail.getItem();
        return axiosClient.get(`detail-quote/${email}`);
    },
    sendLogEmail: (data: ISendLogEmail) => {
        axiosClient.post("email-log", data);
    },
    fetchFavorite: () => {
        const email = lsEmail.getItem();
        return axiosClient.get(`detail-favorite/${email}`);
    },
    saveFavorite: (json: IFavoriteTour[]) => {
        const email = lsEmail.getItem();
        const body = {
            email,
            json,
        };
        axiosClient.post("save-favorite", body);
    },

    deleteFavorite: (payload: IFavoriteTour) => {
        const email = lsEmail.getItem();
        const body = {
            email,
            productId: payload.productId,
        };

        return axiosClient.post(`favourites/delete`, body);
    },
    addFavorite: (payload: IFavoriteTour) => {
        const email = lsEmail.getItem();
        const body = {
            email,
            productId: payload.productId,
            productImagePath: payload.productImagePath,
            rrp: payload.rrp,
            date: payload.date,
            duration: payload.duration,
            location: payload.location,
            name: payload.name,
        };

        return axiosClient.post(`favourites/add`, body);
    },
    getFavourites: () => {
        const email = lsEmail.getItem();
        return axiosClient.get(`favourites/${email}`);
    },
    getFavorite: (page: number, limit: number) => {
        const email = lsEmail.getItem();
        const queryString = `?page=${page}&limit=${limit}`;
        return axiosClient.get(`favorites/${email}${queryString}`);
    },
    getCancelRequests: () => {
        return axiosClient.get(`cancel-requests`);
    },
    getCancelRequest: (bookingReference: string) => {
        return axiosClient.get(`cancel-requests/${bookingReference}`);
    },
    cancelRequest: (payload: ICancelRequest) => {
        const email = lsEmail.getItem();
        const body = {
            ...payload,
            email,
        };
        return axiosClient.post("cancel-requests", body);
    },
    resendVoucher: (orderId: string) => {
        return axiosClient.post(`resend-voucher/${orderId}`);
    },
    shareToCustomer: (data: IShareBookingRequest) => {
        return axiosClient.post("share-booking", data);
    },
};
