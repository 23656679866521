import * as Yup from "yup";

export const passwordSchema = Yup.object().shape({
    new_password: Yup.string()
        .required("This field is required!")
        .min(8, "Password is too short - should be 8 chars minimum."),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("new_password")], "Passwords must match")
        .required("This field is required!"), // Ensure confirmPassword is required
});
