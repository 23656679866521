import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import {
    BreadcrumbsCommon,
    FilterItemComponent,
    StyledSearchResultDesktop,
    ClipLoaderCommon,
    SortMenuComponent,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { breakpoint, useWindowDimensions } from "@utilities";
import { FilterModule, PaginationModule, TourElementModule } from "@module";
import { PATH_HOME, PATH_SEARCH_RESULT } from "@routes";
import {
    AppDispatch,
    clearListTour,
    resetSearch,
    search,
    selectSearchCategories,
    selectSearchTour,
    setCurrentPage,
    setName,
    setSortBy,
    setSortDirection,
    useAppSelector,
} from "@redux";
import { IOptionSearch, IPagination, sortDirection, SortBy } from "@interfaces";
import {
    PAGINATION,
    SORT_NAME_ASC,
    SORT_NAME_DESC,
    SORT_PRICE_ASC,
    SORT_PRICE_DESC,
} from "@constants";

const FILTER_TITLE = "Filter";
const SORT_BY_TITLE = "Sort by";

export const SearchResult = () => {
    //hook
    const dispatch = useDispatch<AppDispatch>();
    // Redux state
    const searchTours = useAppSelector(selectSearchTour);
    const searchCategories = useAppSelector(selectSearchCategories);
    const sortBy = searchCategories.sortBy;
    const sortDirection = searchCategories.sortDirection;
    const listResults = searchTours.listTours;
    // page state
    const [nameValue, setNameValue] = useState<string>(searchCategories.name || "");
    const [isShowSortMenu, setIsShowSortMenu] = useState<boolean>(false);
    const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
    const [optionSort, setOptionSort] = useState<sortDirection>("asc");
    const [sortTitle, setSortTitle] = useState<string>(SORT_BY_TITLE);
    const [listPagePoints, setListPagePoints] = useState<IPagination[]>([]);
    //page variable
    const { width } = useWindowDimensions();
    const isLoadMore = useRef(false);
    const listTabSearch = [
        {
            title: "Search by Keyword",
            icon: "/images/fi_search.png",
        },
    ];
    const breadcrumbsData = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Search result",
            link: PATH_SEARCH_RESULT,
        },
    ];

    // khởi tạo giá trị mặc định cho optionSearch
    const optionSearch: IOptionSearch = {
        "records-start": (searchTours.currentPage - 1) * PAGINATION,
        "records-length": PAGINATION,
        sortBy: "rrp",
        sortDirection: "asc",
    };

    useEffect(() => {
        return () => {
            dispatch(resetSearch());
        };
    }, []);

    useEffect(() => {
        const listPagePointsNew = [];
        if (searchTours.totalRecord > PAGINATION) {
            const totalPage = Math.ceil(searchTours.totalRecord / PAGINATION);
            for (let i = 1; i <= totalPage; i++) {
                const pagePoint = {
                    id: i,
                };
                listPagePointsNew.push(pagePoint);
            }
        }
        setListPagePoints(listPagePointsNew);

        if (sortBy === "name") {
            if (sortDirection === "asc") {
                setSortTitle(SORT_NAME_ASC);
            } else {
                setSortTitle(SORT_NAME_DESC);
            }
        } else if (sortBy === "rrp") {
            if (sortDirection === "asc") {
                setSortTitle(SORT_PRICE_ASC);
            } else {
                setSortTitle(SORT_PRICE_DESC);
            }
        } else {
            setSortTitle(SORT_PRICE_ASC);
        }
    }, [searchTours]);

    useEffect(() => {
        optionSearch.sortBy = sortBy;
        optionSearch.sortDirection = sortDirection;
        dispatch(search(isLoadMore.current, optionSearch));
    }, [searchTours.currentPage, sortBy, sortDirection]);

    const handleToggleShowMenu = (title: string) => {
        if (title === SORT_BY_TITLE) {
            setIsShowSortMenu(!isShowSortMenu);
        } else if (title === FILTER_TITLE) {
            setIsShowFilter(!isShowFilter);
        }
    };

    const handleSetNameValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNameValue(e.target.value);
    };

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleApplyFilter();
        }
    };

    const handleApplyFilter = () => {
        dispatch(setName(nameValue));
        dispatch(search(false, optionSearch));
        dispatch(clearListTour());
        isLoadMore.current = false;
    };

    const handleSetCurrentPage = (page: number) => {
        dispatch(setCurrentPage(page));
    };

    const handleSort = (sort: SortBy, id: number) => {
        if (sort.key === "Name") {
            dispatch(setSortBy("name"));
            dispatch(setSortDirection(sort.value[id].value));
        }
        if (sort.key === "Price") {
            dispatch(setSortBy("rrp"));
            dispatch(setSortDirection(sort.value[id].value));
        }
        if (optionSort === sort.value[id].value) {
            setOptionSort(null);
            setSortTitle(sort.value[id].title);
            dispatch(setCurrentPage(1));
        } else {
            setOptionSort(sort.value[id].value);
            setSortTitle(sort.value[id].title);
            dispatch(setCurrentPage(1));
        }
        isLoadMore.current = false;
    };

    const handleLoadMore = () => {
        isLoadMore.current = true;
        handleSetCurrentPage(searchTours.currentPage + 1);
    };

    return (
        <AppModuleLayout>
            {width < 767 ? (
                <SearchResultMobile>
                    <Container>
                        <FilterMenu isShowFilter={isShowFilter}>
                            <FilterItem
                                onClick={() => {
                                    handleToggleShowMenu(FILTER_TITLE);
                                }}
                            >
                                <img src="/images/fi_sliders.png" alt={FILTER_TITLE} />
                                <span>{FILTER_TITLE}</span>
                            </FilterItem>

                            <FilterItem
                                onClick={() => {
                                    handleToggleShowMenu(SORT_BY_TITLE);
                                }}
                            >
                                {isShowSortMenu && <div className="overlay"></div>}
                                <img src="/images/fi_chevron-down.png" alt="fi_2chevrons-down" />
                                {/* {sortTitle} */}
                                Sort
                                <SortMenuComponent
                                    onSort={handleSort}
                                    isShowSortMenu={isShowSortMenu}
                                    titleSort={sortTitle}
                                />
                            </FilterItem>
                            <div className="filter">
                                <FilterModule
                                    onToggleShowFilter={() => {
                                        handleToggleShowMenu(FILTER_TITLE);
                                    }}
                                    onApplyFilter={handleApplyFilter}
                                />
                            </div>
                        </FilterMenu>
                        <ListSearchResult noItem={!!listResults.length}>
                            {searchTours.loading && !isLoadMore.current ? (
                                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
                            ) : (
                                <InfiniteScroll
                                    dataLength={listResults.length}
                                    next={handleLoadMore}
                                    hasMore={
                                        searchTours.currentPage <
                                        Math.ceil(searchTours.totalRecord / PAGINATION)
                                    }
                                    loader={
                                        <ClipLoaderCommon color="#31B4B9" size={40} margin={20} />
                                    }
                                >
                                    {listResults.length ? (
                                        listResults.map((tour) => {
                                            return (
                                                <TourElementModule
                                                    data={tour}
                                                    key={tour.productId}
                                                />
                                            );
                                        })
                                    ) : searchTours.loading ? (
                                        <></>
                                    ) : (
                                        <p className="notFoundTour">
                                            Sorry, no results found... Try changing your keywords or
                                            clearing your filters
                                        </p>
                                    )}
                                </InfiniteScroll>
                            )}
                        </ListSearchResult>
                    </Container>
                </SearchResultMobile>
            ) : (
                <StyledSearchResultDesktop isShowSortMenu={isShowSortMenu}>
                    <div className="wrapper">
                        <div className="filter">
                            <div className="breadcrumbs">
                                <BreadcrumbsCommon data={breadcrumbsData} />
                            </div>
                            <FilterItemComponent
                                title="Search"
                                listTabs={listTabSearch}
                                nameValue={nameValue}
                                onSetNameValue={handleSetNameValue}
                                onkeyup={handleEnter}
                            />
                            <FilterModule onApplyFilter={handleApplyFilter} />
                        </div>
                        <div className="result">
                            <div className="summary">
                                <div className="numberOfResult">
                                    {searchTours.loading ? "---" : searchTours.totalRecord} Tours
                                    Found
                                </div>
                                <div
                                    className="sortBy"
                                    onClick={() => {
                                        handleToggleShowMenu(SORT_BY_TITLE);
                                    }}
                                >
                                    {isShowSortMenu && <div className="overlay"></div>}
                                    <img
                                        src="/images/fi_2chevrons-down.png"
                                        alt="fi_2chevrons-down"
                                    />
                                    Sort
                                    {/* {sortTitle} */}
                                    <SortMenuComponent
                                        onSort={handleSort}
                                        isShowSortMenu={isShowSortMenu}
                                        titleSort={sortTitle}
                                    />
                                </div>
                            </div>
                            <div className="listSearchResult">
                                {searchTours.loading ? (
                                    <div className="clip-loader">
                                        <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
                                    </div>
                                ) : (
                                    <>
                                        {listResults.length ? (
                                            <>
                                                {listResults.map((tour) => {
                                                    return (
                                                        <TourElementModule
                                                            data={tour}
                                                            key={tour.productId}
                                                        />
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <p className="notFoundTour">
                                                Sorry, no results found... Try changing your
                                                keywords or clearing your filters
                                            </p>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="pagination">
                                {searchTours.totalRecord > PAGINATION && !searchTours.loading && (
                                    <PaginationModule
                                        onSetCurrentPage={handleSetCurrentPage}
                                        listPagePoints={listPagePoints}
                                        currentPage={searchTours.currentPage}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </StyledSearchResultDesktop>
            )}
        </AppModuleLayout>
    );
};

const SearchResultMobile = styled.div`
    position: relative;
    min-height: calc(100vh - 95px);

    ${breakpoint.breakTablet`
        display: none;
    `}
`;

const Container = styled.div`
    padding: 30px 16px 80px 16px;
    background-color: #F2F2F2;
    min-height: calc(100vh - 95px);
`;

const FilterMenu = styled.div<{ isShowFilter: boolean }>`
    display: flex;
    justify-content: space-between;

    .filter {
        transition: 300ms;
        transform: ${(p) => (p.isShowFilter ? "translateX(0)" : "translateX(100%)")};
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        z-index: 3;
        max-height: 100vh;
        overflow: scroll;
    }
`;

const FilterItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    position: relative;
    cursor: pointer;

    span {
        font-size: 16px;
        font-weight: 700;
        margin-left: 10px;
    }

    .overlay {
        background-color: transparent;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 2;
    }
`;

const ListSearchResult = styled.div<{ noItem?: boolean }>`
    min-height: 40vh;
    .notFoundTour {
        text-align: center !important;
        font-size: 20px;
        font-weight: 700;
    }
    .infinite-scroll-component {
        overflow: ${(p) => (!p.noItem ? "inherit !important" : "auto")};
    }
`;
