import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import { route } from "@routes";
import { IRoute } from "@interfaces";
import { FooterModule, HeaderModule } from "@module";
// import { AppDispatch, logout, openToast, resetSavedCart, selectAuth, useAppSelector } from "@redux";
// import { AppDispatch} from "@redux";
// import { useDispatch } from "react-redux";
// import { DURATION_TIME } from "@constants

interface IAppModuleLayout {
    children?: string | JSX.Element | JSX.Element[];
    onBack?: () => void;
}

export const AppModuleLayout = (props: IAppModuleLayout) => {
    const { children, onBack } = props;


//this is the format for the original login response. i am keeping this in case if we need to use expiry time
//For now, we don't have an expiry time in our current login response, so I have commented it.

    // const { auth } = useAppSelector(selectAuth);
    // const dispatch = useDispatch<AppDispatch>();

    // const token = auth?.access_token;
    // // WHAT: check token expire
    // if (token) {
    //     const date = new Date();
    //     const time = date.getTime();
    //     if (auth && time > auth.expires_in) {
    //         // WHAT: toast.warning("Token is expired");
    //         dispatch(
    //             openToast({
    //                 message: "Login session has expired!",
    //                 autoHideDuration: DURATION_TIME,
    //                 type: "warning",
    //             }),
    //         );
    //         dispatch(logout());
    //         dispatch(resetSavedCart());
    //     }
    // }

    return (
        <AppLayoutWrapper>
            <HeaderModule onBack={onBack} />
            {!!children && children}
            <FooterModule />
        </AppLayoutWrapper>
    );
};

export const AppLayout = () => {
    return (
        <Container>
            <Routes>
                {route.map((route: IRoute, key) => (
                    <Route key={key} path={route.path} element={<route.element />} />
                ))}
            </Routes>
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
`;

const AppLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${(props) => props.theme.colors.gray_6};
`;
