import { FilterBookingComponent } from "@components";
import { IFilterBooking, IListBookings } from "@interfaces";
import { SelectChangeEvent } from "@mui/material";

interface IProps {
    data: IListBookings[];
    handleChange: (event: SelectChangeEvent<number>) => void;
    optionModels: IFilterBooking[];
    filterBookingOption: number;
    handleChangeDateFrom: (date: Date, value?: string | null) => void;
    dateFrom: Date | null;
    handleChangeDateTo: (date: Date, value?: string | null) => void;
    dateTo: Date | null;
    clearDate: () => void;
}

export const FilterBookingModule = (props: IProps) => {
    const {
        data,
        handleChange,
        optionModels,
        filterBookingOption,
        handleChangeDateFrom,
        dateFrom,
        handleChangeDateTo,
        dateTo,
        clearDate,
    } = props;

    return (
        <FilterBookingComponent
            optionModels={optionModels}
            selectedOption={filterBookingOption}
            handleChange={handleChange}
            handleChangeDateFrom={handleChangeDateFrom}
            dateFrom={dateFrom}
            handleChangeDateTo={handleChangeDateTo}
            dateTo={dateTo}
            data={data}
            clearDate={clearDate}
        />
    );
};
