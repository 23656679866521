import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
    StyledBookingDetail,
    MenuAccountComponent,
    BreadcrumbsCommon,
    ClipLoaderCommon,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_HOME, PATH_SAVED_CART } from "@routes";
import { selectSavedCarts, selectStatusApp, useAppSelector } from "@redux";
import { SavedCartItemModule, CheckoutProductModule } from "@module";
import { breakpoint, useWindowDimensions } from "@utilities";
import { ISavedCart } from "@interfaces";

export const SavedCartDetail = () => {
    // page hooks
    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    // page redux
    const { carts } = useAppSelector(selectSavedCarts);
    const statusApp = useAppSelector(selectStatusApp).status;
    // page state
    const [savedCart, setSavedCart] = useState<ISavedCart>();
    //page variable
    const breadcrumbsModel = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Quotes",
            link: PATH_SAVED_CART,
        },
        {
            title: "Details",
            link: `${PATH_SAVED_CART}/${id}`,
        },
    ];

    useEffect(() => {
        if (id) {
            const cart = carts.find((item) => item.id === id);
            if (cart) {
                setSavedCart(cart);
            } else {
                navigate(PATH_SAVED_CART);
            }
        } else {
            navigate(PATH_SAVED_CART);
        }
    }, [id]);

    if (!savedCart) {
        return (
            <AppModuleLayout>
                <ClipLoaderCommon color="#31B4B9" size={70} margin={150} />
            </AppModuleLayout>
        );
    }

    const totalItem = savedCart.cart.carts.reduce((total, cart) => {
        return total + cart.quantity;
    }, 0);

    return (
        <AppModuleLayout>
            <StyledBookingDetail isLogin={true}>
                <div className="wrapper">
                    <div className="breadCrumbs">
                        <BreadcrumbsCommon data={breadcrumbsModel} />
                    </div>
                    <div className="menu">
                        <MenuAccountComponent />
                    </div>
                    <div className="detailWrapper">
                        <Link to={PATH_SAVED_CART} className="navigateBtn">
                            <img src="/images/fi_arrow-left.png" alt="" />
                            <span>Quotes</span>
                        </Link>
                        <SavedCartItemModule data={savedCart} />
                        <Container>
                            <div className="userInfoNPrice">
                                <div className="userInfo">
                                    <p className="subHeading">Lead customer</p>
                                    {/* Mặc định thăng redeemers đầu tiền data trả về là Lead customer> */}
                                    <p className="email">
                                        {savedCart.cart.customers[0].firstName +
                                            " " +
                                            savedCart.cart.customers[0].lastName}
                                    </p>
                                    <p className="email">{savedCart.cart.customers[0].email}</p>
                                    <p className="email">+{savedCart.cart.customers[0].phone}</p>
                                    {/* Nhưng thằng redeemers sau là của Additional customer */}
                                    {savedCart.cart.customers.length > 1 && (
                                        <>
                                            <p style={{ marginTop: "5px" }} className="subHeading">
                                                Additional customer
                                            </p>

                                            {savedCart.cart.customers.map((customer, index) => {
                                                if (index === 0) return <></>; // bỏ thằng đàu vì nó là lead customer
                                                return (
                                                    <p className="additionalCustomer" key={index}>
                                                        {`${customer.firstName} ${customer.lastName}`}
                                                    </p>
                                                );
                                            })}
                                        </>
                                    )}
                                </div>
                                <div className="totalPrice">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="field">Number of Items</div>
                                                </td>
                                                <td></td>
                                                {/* <td><div className="value">{bookingInfo.numberOfItem} Items</div></td> */}
                                                <td>
                                                    <div className="value">{totalItem} Items</div>
                                                </td>
                                            </tr>
                                            {statusApp && (
                                                <>
                                                    <tr>
                                                        <td>
                                                            <div className="field">Total Net:</div>
                                                        </td>
                                                        {/* <td><div className="commissionPrice">{bookingInfo.commissionPercent}%</div></td> */}
                                                        {/* <td><div className="value commissionPrice">${bookingInfo.commissionPrice}</div></td> */}
                                                        <td></td>
                                                        <td>
                                                            <div className="value ">
                                                                $
                                                                {savedCart.cart.totalNet.toFixed(2)}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="field">Commission</div>
                                                        </td>
                                                        {/* <td><div className="commissionPrice">{bookingInfo.commissionPercent}%</div></td> */}
                                                        {/* <td><div className="value commissionPrice">${bookingInfo.commissionPrice}</div></td> */}
                                                        <td>
                                                            <div className="commissionPrice">
                                                                {savedCart.cart.commissionPercent.toFixed(
                                                                    2
                                                                )}
                                                                %
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="value commissionPrice">
                                                                $
                                                                {savedCart.cart.commission.toFixed(
                                                                    2
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="total">
                                        <span className="text">Total Sell Price</span>
                                        {/* <span className="value">${Number(bookingInfo.totalPrice).toFixed(2)}</span> */}
                                        <span className="value">
                                            ${savedCart.cart.totalPrice.toFixed(2)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        {width > 1024 && <p className="heading1">Quote Detail</p>}
                        <div className="listFare">
                            {width <= 1024 && <p className="heading">Quote Detail</p>}
                            <ListProduct>
                                {savedCart.cart.carts?.map((product) => {
                                    return (
                                        <CheckoutProductModule
                                            product={product}
                                            key={product.id}
                                            customers={savedCart.cart.customers}
                                        />
                                    );
                                })}
                            </ListProduct>
                        </div>
                    </div>
                </div>
            </StyledBookingDetail>
        </AppModuleLayout>
    );
};

const ListProduct = styled.div`
    .infoWrapper {
        padding-bottom: 7px;
    }
`;

const Container = styled.div`
    .generalInfo {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 28px 0;

        img {
            margin-bottom: 27px;

            ${breakpoint.breakLarge`
                width: 124px;
                margin-bottom: 6px;
            `}
        }

        .userName {
            font-size: 18px;
            font-weight: 700;
            line-height: 23px;
            color: ${(props) => props.theme.colors.black};
            margin-bottom: 2px;

            ${breakpoint.breakLarge`
                font-size: 24px;
                line-height: 31px;
                margin-bottom: 14px;
            `}
        }

        .bookingId {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
            color: ${(props) => props.theme.colors.main};
            margin-bottom: 2px;

            ${breakpoint.breakLarge`
                font-size: 36px;
                line-height: 47px;
                margin-bottom: 11px;
            `}
        }

        .bookingDate {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: ${(props) => props.theme.colors.gray_2};
        }
    }

    .userInfo {
        margin-top: 16px;
        background-color: #fff;
        padding: 18px 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: ${(props) => props.theme.colors.gray_1};
        text-align: left;

        ${breakpoint.breakTablet`
            flex: 1;
            padding: 15px 0 7px 0;
            font-size: 12px;
            line-height: 20px;
        `}

        ${breakpoint.breakLarge`
            padding: 21px 0 21px 0;
            font-size: 16px;
            line-height: 21px;
        `}

        .subHeading {
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
            color: ${(props) => props.theme.colors.black};
            margin-bottom: 4px;
            padding-top: 4px;

            ${breakpoint.breakTablet`
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 0px;
            `}

            ${breakpoint.breakLarge`
                font-size: 16px;
                font-weight: 700;
                line-height: 21px;
                margin-bottom: 4px;
            `}
        }

        .email {
            margin-bottom: 4px;

            ${breakpoint.breakTablet`
                margin-bottom: 0;
            `}

            ${breakpoint.breakLarge`
                margin-bottom: 4px;
            `}
        }

        .phoneNumber {
            margin-bottom: 12px;

            ${breakpoint.breakTablet`
                margin-bottom: 3px;
            `}

            ${breakpoint.breakLarge`
                margin-bottom: 17px;
            `}
        }

        & > p {
            ${breakpoint.breakTablet`
                padding-left: 17px;
            `}
        }
    }

    .totalPrice {
        padding: 15px 0 15px 0;
        background-color: #fff;
        margin-top: 16px;
        flex: 1;

        ${breakpoint.breakTablet`
            margin-left: 18px;
        `}

        ${breakpoint.breakIpadPro`
            margin-left: 18px;
        `}

        ${breakpoint.breakLarge`
            padding: 21px 0 0 0;
        `}

        table {
            padding: 0 17px;
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 19px;
            color: ${(props) => props.theme.colors.gray_1};

            ${breakpoint.breakTablet`
                font-size: 13px;
                line-height: 13px;
            `}

            ${breakpoint.breakLarge`
                font-size: 16px;
                line-height: 26px;
            `}
        }

        tr {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 17px;

            .commissionPrice {
                color: #f43f3f;
            }
        }

        .total {
            padding: 14px 17px 0 17px;
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            color: ${(props) => props.theme.colors.black};
            display: flex;
            justify-content: space-between;
            border-top: 1px solid ${(props) => props.theme.colors.gray_5};

            ${breakpoint.breakTablet`
                font-size: 14px;
                line-height: 15px;
            `}

            ${breakpoint.breakLarge`
                padding: 16px 17px 0 17px;
                font-size: 20px;
                font-weight: 500;
                line-height: 32px;
            `}

            .value {
                font-weight: 700;
                color: ${(props) => props.theme.colors.main};
            }
        }
    }
`;
