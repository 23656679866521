import { useState } from "react";
import parse from 'html-react-parser';
import moment from "moment-timezone";
import {
    CancelButton,
    CancelPopupComponent,
    StyledCardBody,
    StyledCardHeader,
    StyledExpandedCard,
} from "@components";
import { IProductsOfBooking } from "@interfaces";
import { useWindowDimensions } from "@utilities";

interface IProps {
    data: IProductsOfBooking;
    isShowCancel?: boolean;
    onCancelRequest?: (
        voucherNumber: string,
        quantity: string,
        reason: string,
        supplierName: string,
        productName: string,
        fareName: string,
        cancelDes: string,
        cancelRules: {
            daysBeforeTravelDate: string;
            feeAmount: string;
        }[]
    ) => void;
    onTogglePopup?: () => void;
}

export const BookingDetailItemModule = (props: IProps) => {
    const { data, isShowCancel, onCancelRequest } = props;
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    const { width } = useWindowDimensions();

    const handleToggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const dateFormat = (date: string | null) => {
        return moment(date).tz("Australia/Melbourne").format("DD MMMM YYYY");
    };

    // data.redeemers.reduce(())

    const handleTogglePopup = () => {
        if (!isShowPopup) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "overlay";
        }
        setIsShowPopup(!isShowPopup);
    };

    const quantityLeft = Number(data.qty) - Number(data.cancelQuantity || "0");

    return (
        <>
            <StyledExpandedCard>
                <StyledCardHeader onClick={handleToggleExpanded} isExpanded={isExpanded}>
                    <p className="voucherNumber">Voucher number: {data.voucherNumber}</p>
                    <p className="title">{data.name}</p>
                    <div className="expandBtn">
                        <img src="/images/expandIcon.png" alt="expandIcon" />
                    </div>
                    {!isExpanded && data.cancelRequest && (
                        <div className="cancel-request">Refund Requested</div>
                    )}
                </StyledCardHeader>
                <StyledCardBody isExpanded={isExpanded}>
                    <div className="infoBlock top">
                        <div className="infoItem marginBottom12">
                            <span className="fieldName">Fare type: </span>
                            <span className="value">{data.fareName}</span>
                        </div>
                        <div className="infoItem marginBottom12">
                            <span className="fieldName">Tour code: </span>
                            <span className="value">{data.tourCode}</span>
                        </div>
                        <div className="infoItem marginBottom12">
                            <span className="fieldName">start location: </span>
                            <span className="value">{data.startLocation}</span>
                        </div>
                        <div className="infoItem marginBottom12">
                            <span className="fieldName">end location: </span>
                            <span className="value">{data.endLocation}</span>
                        </div>
                        <div className="infoBreak"></div>
                        <div className="infoItem marginBottom12">
                            <span className="fieldName">Quantity of fare type: </span>
                            <span className="value">
                                {Number(data.qty) < 10 ? `0${data.qty}` : data.qty}
                            </span>
                        </div>
                        <div className="infoItem marginBottom12">
                            <span className="fieldName">Operator Booking Reference: </span>
                            <br />
                            <span className="value">
                                {data.redeemers.map((redeemer) =>
                                    redeemer.bookingDetails.map((bookingDetail) => (
                                        <p>
                                            {redeemer.name} - {bookingDetail.eBookingReferenceId}
                                        </p>
                                    ))
                                )}
                            </span>
                        </div>

                        <div className="infoBreak"></div>
                        {/* <div className="infoItem flexColumn">
                            <span className="fieldName">Customer Details</span>
                            {data.redeemers.map((redeemer, index) => {
                                return (
                                    <span className="value" key={index}>
                                        {redeemer.name}
                                    </span>
                                );
                            })}
                            {data.redeemers.map((redeemer, index) => {
                                return (
                                    <Fragment key={index}>
                                        {redeemer.bookingDetails.map((bookingDetails, index) => {
                                            return (
                                                <div className="infoBlock middle" key={index}>
                                                    <div className="infoItem marginBottom12">
                                                        <span className="fieldName">
                                                            Booking date:{" "}
                                                        </span>
                                                        <span className="value">
                                                            {bookingDetails.travelDate}
                                                        </span>
                                                    </div>
                                                    <div className="infoItem marginBottom12">
                                                        <span className="fieldName">
                                                            Commencement Time:{" "}
                                                        </span>
                                                        <span className="value">
                                                            {bookingDetails.detailComment}
                                                        </span>
                                                    </div>
                                                    <div className="infoItem flexColumn marginBottom12">
                                                        <span className="fieldName">
                                                            Pick up location:
                                                        </span>
                                                        <div
                                                            className={`${
                                                                width >= 767
                                                                    ? "flexRow"
                                                                    : "flexColumn"
                                                            }`}
                                                        >
                                                            <span className="value pickupLocation">
                                                                {bookingDetails.pickupLocation}
                                                            </span>
                                                            <span className="value checkInTime">
                                                                Check in from:{" "}
                                                                {bookingDetails.pickupTime}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="infoItem">
                                                        <span className="fieldName">
                                                            EBooking Ref:{" "}
                                                        </span>
                                                        <span className="value">
                                                            {bookingDetails.eBookingReferenceId}
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </Fragment>
                                );
                            })}
                        </div> */}
                    </div>
                    {data.redeemers[0].bookingDetails.map((bookingDetail, index) => (
                        <div
                            className={`infoBlock middle ${
                                index !== data.redeemers[0].bookingDetails.length - 1 ? "break" : ""
                            }`}
                        >
                            <div className="infoItem marginBottom12">
                                <span className="fieldName">Customer: </span>
                                <span className="value">
                                    {data.redeemers
                                        .filter((redeemer) =>
                                            redeemer.bookingDetails.some(
                                                (detail) =>
                                                    detail.eBookingReferenceId ===
                                                    bookingDetail.eBookingReferenceId
                                            )
                                        )
                                        .map((user) => `${user.name}, `)}
                                </span>
                            </div>
                            <div className="infoItem marginBottom12">
                                <span className="fieldName">Booking Date: </span>
                                <span className="value">
                                    {dateFormat(bookingDetail?.travelDate)}
                                </span>
                            </div>
                            {bookingDetail?.commencementTime && (
                                <div className="infoItem marginBottom12">
                                    <span className="fieldName">Commencement Time: </span>
                                    <span className="value">{bookingDetail?.commencementTime}</span>
                                </div>
                            )}
                            {bookingDetail?.pickupLocation && (
                                <div className="infoItem marginBottom12">
                                    <span className="fieldName">Pickup location: </span>
                                    <span className="value">
                                        {bookingDetail?.pickupTime} {bookingDetail?.pickupLocation}
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
                    <div className="infoBreak"></div>
                    <div className="infoBlock middle">
                        <div className="infoItem marginBottom12">
                            <span className="fieldName">Operator Name: </span>
                            <span className="value">{data.supplier.name}</span>
                        </div>
                        <div className="infoItem flexColumn">
                            <span className="fieldName">Operator Contact: </span>
                            <div className={`listPhone ${width >= 767 ? "flexRow" : "flexColumn"}`}>
                                <p className="value phoneItem">{data.supplier.email}</p>
                                <p className="value phoneItem">{data.supplier.phone}</p>
                            </div>
                        </div>
                    </div>
                    <div className="infoBreak"></div>
                    <div className="infoBlock bottom">
                        <div className="infoItem marginBottom12">
                            <span className="fieldName">important information: </span>
                        </div>
                        {data.redeemers[0]?.bookingDetails[0]?.levy ? (
                            <div className="infoItem marginBottom12">
                                <span className="fieldName">Pay on Arrival: </span>
                                <span className="value">
                                    {data.redeemers[0]?.bookingDetails[0]?.levy} x{" "}
                                    {data.redeemers.length}
                                </span>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="infoItem marginBottom12">
                            {parse(data.instructions)}
                        </div>

                        <div className="infoItem marginBottom12">
                            <span className="value">
                                {data.redeemers[0]?.bookingDetails[0]?.comments}
                            </span>
                        </div>
                        <div className="infoItem marginBottom12">
                            <span className="fieldName">Cancellation Policy: </span>
                            <p className="value">
                                {
                                    data.redeemers[0]?.bookingDetails[0]
                                        ?.cancellationFeePolicyDescription
                                }
                            </p>
                            <br />
                            {data.redeemers[0]?.bookingDetails[0]?.cancellationFeePolicyRules?.map(
                                (rule) => (
                                    <p className="value">
                                        Cancelled {rule.daysBeforeTravelDate} days before travel:{" "}
                                        {rule.feeAmount}% fee
                                    </p>
                                )
                            )}
                            <br />
                            <p className="value">
                                Please note any cancellation has a 10% cancellation processing fee
                                PLUS the operator fee above
                            </p>
                        </div>

                        {isShowCancel && (
                            <div className="cancelBtn">
                                <CancelButton onClick={handleTogglePopup}>
                                    Request to Cancel Voucher
                                </CancelButton>
                            </div>
                        )}
                    </div>
                </StyledCardBody>
            </StyledExpandedCard>
            {isShowPopup && onCancelRequest && quantityLeft > 0 && (
                <CancelPopupComponent
                    qty={quantityLeft}
                    item={data}
                    onCancelRequest={onCancelRequest}
                    onTogglePopup={handleTogglePopup}
                    cancelDes={
                        data.redeemers[0]?.bookingDetails[0]?.cancellationFeePolicyDescription
                    }
                    cancelRules={data.redeemers[0]?.bookingDetails[0]?.cancellationFeePolicyRules}
                />
            )}
        </>
    );
};
