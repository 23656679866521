export const IconChain = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.6654 13.9987C10.6654 13.9987 10.9017 14.1862 11.5766 14.5549C12.2516 14.9237 12.9979 15.143 13.7651 15.1979C14.5322 15.2529 15.3022 15.1422 16.0228 14.8734C16.7435 14.6045 17.3978 14.1839 17.9416 13.64L21.1597 10.4218C22.1368 9.41021 22.6774 8.05535 22.6652 6.64903C22.6529 5.24271 22.0889 3.89745 21.0944 2.903C20.0999 1.90854 18.7547 1.34445 17.3484 1.33223C15.942 1.32001 14.5872 1.86063 13.5756 2.83766L11.7305 4.67201M13.332 9.9987C13.332 9.9987 13.0957 9.78977 12.4208 9.42101C11.7458 9.05226 10.9994 8.83297 10.2323 8.77803C9.46515 8.72309 8.69516 8.83378 7.97455 9.10259C7.25394 9.37139 6.59957 9.79203 6.05582 10.336L2.83766 13.5541C1.86063 14.5657 1.32001 15.9206 1.33223 17.3269C1.34445 18.7332 1.90854 20.0785 2.903 21.0729C3.89745 22.0674 5.24271 22.6315 6.64903 22.6437C8.05535 22.6559 9.41021 22.1153 10.4218 21.1383L12.2562 19.3039"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
