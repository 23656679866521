import { useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { CONSTANT_CONTACT, CONSTANT_INFORMATION } from "@constants";
import {
    SendEmailComponent,
    ResetPassComponent,
    LoginLogo,
    AuthenticationComponent,
} from "@components";
import { IAuthenticationCode, IForgotPassEmail, IResetPassword } from "@interfaces";
import { forgotPassEmailSchema, resetPassSchema } from "@validations";
import { AppDispatch, toggleLoading } from "@redux";
import { authApi } from "@api";
import { SuccessToast } from "src/Components/toasts/SuccessToast";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { useNavigate } from "react-router-dom";

export function ForgotPassword() {
    const SEND_EMAIL_SCREEN = "send_email_screen";
    const RESET_PASSWORD_SCREEN = "reset_password_screen";
    const AUTHENTICATION_CODE_SCREEN = "authentication_code_screen";
    const [screen, setScreen] = useState(SEND_EMAIL_SCREEN);
    const dispatch = useDispatch<AppDispatch>();
    const constantText = `${CONSTANT_INFORMATION}`;
    const constantContact = `Contact : ${CONSTANT_CONTACT}`;
    const initialValuesEmail: IForgotPassEmail = { email: "" };
    const initialValuesCode: IAuthenticationCode = { otp: "" };
    const initialValuesPassword: IResetPassword = { password: "", confirmPassword: "" };
    const [email, setEmail] = useState(initialValuesEmail.email);
    const [otp, setOtp] = useState(initialValuesCode.otp);
    const navigate = useNavigate();

    return (
        <>
            <LoginLogo src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg" />

            {screen === SEND_EMAIL_SCREEN && (
                <Formik
                    initialValues={initialValuesEmail}
                    validationSchema={forgotPassEmailSchema}
                    onSubmit={async (values) => {
                        const emailData = { email: values.email };
                        setEmail(values.email);
                        try {
                            dispatch(toggleLoading(true));
                            const res = await authApi.forgotPassEmail(emailData);
                            SuccessToast({ Message: res.data.message });
                            setScreen(AUTHENTICATION_CODE_SCREEN);
                        } catch (err: any) {
                            ErrorToast({ Message: err.response.data.message });
                        } finally {
                            dispatch(toggleLoading(false));
                        }
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <SendEmailComponent
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                            constantText={constantText}
                            constantContact={constantContact}
                        />
                    )}
                </Formik>
            )}

            {screen === AUTHENTICATION_CODE_SCREEN && (
                <Formik
                    initialValues={initialValuesCode}
                    onSubmit={async (values, { setErrors }) => {
                        const codeData = { otp: values.otp, email: email };
                        setOtp(values.otp);
                        try {
                            dispatch(toggleLoading(true));
                            const res = await authApi.forgotPassCode(codeData);
                            if (res.data.code === 200) {
                                SuccessToast({ Message: res.data.message });
                                setScreen(RESET_PASSWORD_SCREEN);
                            }
                        } catch (err: any) {
                            if (err.response.data.data?.otp?.[0]) {
                                console.log("ggwp");
                                setErrors({ otp: err.response.data.data.otp?.[0] });
                            } else {
                                ErrorToast({ Message: err.response.data.message });
                            }
                        } finally {
                            dispatch(toggleLoading(false));
                        }
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting,errors,
                    touched,}) => (
                        <AuthenticationComponent
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                            constantText={constantText}
                            constantContact={constantContact}
                            enteredEmail={email}
                            errors={errors}
                            touched={touched}
                        />
                    )}
                </Formik>
            )}

            {screen === RESET_PASSWORD_SCREEN && (
                <Formik
                    initialValues={initialValuesPassword}
                    validationSchema={resetPassSchema}
                    onSubmit={async (values) => {
                        const passwordData = {
                            new_password: values.password,
                            otp: otp,
                            email: email,
                        };
                        try {
                            dispatch(toggleLoading(true));
                            const res = await authApi.forgotPass(passwordData);
                            if (res.data.code === 200) {
                                SuccessToast({ Message: res.data.message });
                                navigate("/login");
                            }
                        } catch (err: any) {
                            ErrorToast({ Message: err.response.data.message });
                        } finally {
                            dispatch(toggleLoading(false));
                        }
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <ResetPassComponent
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            handleSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                            constantText={constantText}
                            constantContact={constantContact}
                        />
                    )}
                </Formik>
            )}
        </>
    );
}
