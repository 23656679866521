import { IResetPassword, IHandleChange, IHandleBlur, IHandleSubmit } from "@interfaces";
import { PATH_LOGIN } from "@routes";
import { useNavigate, NavLink } from "react-router-dom";
import styled from "styled-components";
import { InputCommon } from "../Common";
import { StyledButton, StyledBorderButton, StyledLink, StyledForm } from "../StyledComponents";

interface ResetPassFormProps {
    values: IResetPassword;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    isSubmitting: boolean;
    constantText: string;
    constantContact: string;
}

export const ResetPassComponent = (props: ResetPassFormProps) => {
    const { values, handleChange, handleBlur, handleSubmit, constantText ,constantContact} = props;
    const navigate = useNavigate();

    const handleBackToLogin = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        navigate(PATH_LOGIN);
    };

    return (
        <>
            <StyledWrapper onSubmit={handleSubmit}>
                <h2 className="heading">Reset Password</h2>
                <p className="subHeading">
                    Enter a new password to reset the password on your account. We’ll ask for this
                    password everywhere you log in.
                </p>
                <InputCommon
                    name="password"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.password}
                    label="New password"
                    type="password"
                />
                <InputCommon
                    name="confirmPassword"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.confirmPassword}
                    label="Confirm New password"
                    type="password"
                />
                <StyledButton
                    borderRadius="4px"
                    color={["#436CB2 0%", "#28B7C3 100%"]}
                    className="send_link"
                    type="submit"
                >
                    Continue
                </StyledButton>
                <StyledBorderButton onClick={handleBackToLogin}>Back to Login</StyledBorderButton>
                <StyledLink>
                    Don't have an account? <NavLink to="/signup"> Sign up</NavLink>, to start
                    selling today.
                </StyledLink>
                <StyledLine className="contact">{constantContact}</StyledLine>
                <StyledLine>{constantText}</StyledLine>
            </StyledWrapper>
        </>
    );
};

const StyledWrapper = styled(StyledForm)`
    .heading {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 18px;
    }

    .subHeading {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: ${(props) => props.theme.colors.gray_2};
    }
    .subHeading span {
        font-weight: 700 !important;
    }
    .send_link {
        margin-top: 30px;
    }
`;

const StyledLine = styled.div`
    font-weight: 100;
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray_1};
    margin: 10px 0px;
`;
