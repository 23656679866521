import styled from "styled-components";

// Styled component for the close button
const CloseButtonContainer = styled.i`
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 5px;
    cursor: pointer; // Add a pointer cursor for better UX

    img {
        width: 24px; // Set a specific width for the images (optional)
        height: 24px; // Set a specific height for the images (optional)
    }
`;

export const ToastCloseButton = ({ closeToast }: any) => (
    <CloseButtonContainer onClick={closeToast}>
        <img src="/images/toast/Divisor.svg" alt="divider" />
        <img src="/images/toast/Close.svg" alt="close button" />
    </CloseButtonContainer>
);
