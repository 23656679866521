import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { IconCart, IconFileText, IconHeart, IconLogOut, IconUser, IconLock, IconChain } from "@components";
import { AppDispatch, logout, resetSavedCart } from "@redux";
import {
    PATH_MY_ACCOUNT,
    PATH_BOOKING,
    PATH_BOOKING_DETAIL,
    PATH_FAVORITES,
    PATH_SAVED_CART,
    PATH_UPDATE_PASSWORD,
    PATH_LINK_AGENT_ACCOUNT,
} from "@routes";
import { breakpoint, ssPathname } from "@utilities";

export const MenuAccountComponent = () => {
    const dispatch = useDispatch<AppDispatch>();
    const pathname = window.location.pathname;

    const handleLogout = () => {
        ssPathname.removeItem();
        dispatch(logout());
        dispatch(resetSavedCart());
    };
    const getLinkClassName = ({ isActive }: { isActive: boolean }) =>
        isActive ? "linkItem active" : "linkItem";

    return (
        <Container>
            <NavLink className={getLinkClassName} to={PATH_MY_ACCOUNT}>
                <div className="itemWrapper">
                    <span className="icon">
                        <IconUser />
                    </span>
                    <span className="linkText">Update My Details</span>
                </div>
            </NavLink>
            <NavLink className={getLinkClassName} to={PATH_FAVORITES}>
                <div className="itemWrapper">
                    <span className="icon">
                        <IconHeart />
                    </span>
                    <span className="linkText">Favourites</span>
                </div>
            </NavLink>
            <NavLink
                className={`linkItem ${pathname === PATH_BOOKING_DETAIL ? "active" : ""}`}
                to={PATH_BOOKING}
            >
                <div className="itemWrapper">
                    <span className="icon">
                        <IconFileText />
                    </span>
                    <span className="linkText">Bookings</span>
                </div>
            </NavLink>
            <NavLink className={getLinkClassName} to={PATH_SAVED_CART}>
                <div className="itemWrapper">
                    <span className="icon">
                        <IconCart />
                    </span>
                    <span className="linkText">Quotes</span>
                </div>
            </NavLink>
            <NavLink className={getLinkClassName} to={PATH_UPDATE_PASSWORD}>
                <div className="itemWrapper">
                    <span className="icon">
                        <IconLock />
                    </span>
                    <span className="linkText">Update Password</span>
                </div>
            </NavLink>
            <NavLink className={getLinkClassName} to={PATH_LINK_AGENT_ACCOUNT}>
                <div className="itemWrapper">
                    <span className="icon">
                        <IconChain />
                    </span>
                    <span className="linkText">Link Agent Account</span>
                </div>
            </NavLink>
            <div className="linkItem logOut" onClick={handleLogout}>
                <div className="itemWrapper">
                    <span className="icon">
                        <IconLogOut />
                    </span>
                    <span className="linkText">Logout</span>
                </div>
            </div>
        </Container>
    );
};

const Container = styled.div`
    background-color: #fff;
    padding-left: 20px;
    width: 177px;
    margin-right: 20px;

    ${breakpoint.breakIpadPro`
        width: 230px;
        margin-right: 45px;
    `}

    ${breakpoint.breakLarge`
        width: 320px;
        margin-right: 60px;
        padding-left: 36px;
    `}

    .linkItem {
        cursor: pointer;

        .itemWrapper {
            padding: 10px 0;
            display: block;
            display: flex;
            border-bottom: 1px solid ${(p) => p.theme.colors.gray_5};
            align-items: center;
            position: relative;

            ${breakpoint.breakLarge`
                padding: 33px 0;
            `}

            .icon {
                svg {
                    width: 17px;

                    ${breakpoint.breakLarge`
                        width: unset;
                    `}
                }
            }

            .linkText {
                margin-left: 16px;
                font-size: 14px;

                ${breakpoint.breakLarge`
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 23px;
                `}
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 5px;
                height: 100%;
                background-color: ${(p) => p.theme.colors.main};
                display: none;
            }
        }

        &:last-child {
            .itemWrapper {
                border-bottom: none;

                ${breakpoint.breakLarge`
                    padding: 33px 0;
                `}
            }
        }

        &.active {
            .itemWrapper::before {
                display: block;
            }

            .itemWrapper .icon svg {
                path {
                    stroke: ${(p) => p.theme.colors.main};
                }
            }
        }
    }

    .logOut {
        color: ${(p) => p.theme.colors.red};

        .icon svg {
            path {
                stroke: ${(p) => p.theme.colors.red};
            }
        }
    }
`;
