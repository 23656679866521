import { toast } from "react-toastify";
import styles from "../../style.module.css";
import { ToastCloseButton } from "./ToastCloseButton";

interface ToastMessage {
  Message: string;
}

export const ErrorToast = ({ Message }: ToastMessage) => {
  // eslint-disable-next-line no-console
  console.error(Message);
  toast.error(Message, {
    className: styles.toastError,
    closeButton: ToastCloseButton,
    icon: () => <img src="/images/toast/Error.svg" />,
    progressStyle: { background: "#E72A11" },
  });
};
