import { Formik } from "formik";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";

import { ICustomerInfo, ICustomerWithCart } from "@interfaces";
import { CustomerDetailFormComponent, BinSymbolCommon } from "@components";
import { customerDetailSchema } from "@validations";
import { breakpoint, useWindowDimensions } from "@utilities";
import { AppDispatch, checkPayment, editCustomer, openToast, removeCustomer } from "@redux";
import { externalApi } from "@api";
import { DURATION_TIME } from "@constants";

interface IProps {
    onCloseCustomerDetail?: () => void;
    customer?: ICustomerWithCart;
}

export const CustomerFormModule = (props: IProps) => {
    //page props
    const { onCloseCustomerDetail, customer } = props;
    //page hooks
    const dispatch = useDispatch<AppDispatch>();
    const { width } = useWindowDimensions();
    //page state
    const [countriesData, setCountriesData] = useState([]);
    const [reRender, setReRender] = useState<boolean>(false);
    const [isShowErrorDate, setIsShowErrorDate] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<ICustomerInfo>({
        title: `${customer?.isLeader ? "Lead Customer Details" : "Additional Customer Details"}`,
        firstName: customer?.firstName || "",
        lastName: customer?.lastName || "",
        phone: customer?.phone || "",
        email: customer?.email || "",
        postCode: customer?.postCode || "",
        country: customer?.country || null,
        qty: customer?.isLeader ? 1 : 0,
        dateOfBirth: customer?.dateOfBirth || null,
    });
    //page variable
    const emptyValue = {
        title: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        postCode: "",
        country: null,
        qty: customer?.isLeader ? 1 : 0,
        dateOfBirth: "",
    };

    const emptyTouched = {
        firstName: false,
        lastName: false,
        phone: false,
        email: false,
        postCode: false,
        country: false,
    };

    const handleResetCustomer = () => {
        // dispatch(resetCustomer({ customerId: userId || "" }));
        const inputDateMobile = document.querySelector(
            `.customerDetail#customer_${customer?.id} input[type='date']`,
        );
        const inputDateDesktop = document.querySelector(
            `input[type='date']#dateOfBirth${customer?.id}`,
        );
        if (inputDateMobile) {
            //@ts-ignore
            inputDateMobile.value = "";
        }
        if (inputDateDesktop) {
            //@ts-ignore
            inputDateDesktop.value = "";
        }
    };

    const handleRemoveCustomer = () => {
        if (width >= 767) {
            dispatch(removeCustomer({ customerId: customer?.id || "" }));
        } else {
            if (onCloseCustomerDetail) {
                onCloseCustomerDetail();
            }
            setTimeout(() => {
                dispatch(removeCustomer({ customerId: customer?.id || "" }));
            }, 300);
        }
    };

    useEffect(() => {
        (async () => {
            const res = await externalApi.getCountriesDate();
            setCountriesData(res.data);
        })();
    }, []);

    const handleChangeDate = (date: Date | null) => {
        setIsShowErrorDate(true);
        setInitialValues((prevValues) => ({
            ...prevValues,
            dateOfBirth: date,
        }));
    };
    const dateValue: Date | null =
        initialValues?.dateOfBirth && typeof initialValues.dateOfBirth === "string"
            ? new Date(initialValues.dateOfBirth)
            : initialValues?.dateOfBirth instanceof Date
              ? initialValues.dateOfBirth
              : null;

    return (
        <Container>
            <Header>
                <p className="heading">
                    {customer?.isLeader ? "Lead Customer Details" : "Additional Customer Details"}
                </p>
                <div
                    className="closeBtn"
                    onClick={() => {
                        if (onCloseCustomerDetail) {
                            onCloseCustomerDetail();
                        }
                        setReRender(!reRender);
                    }}
                >
                    &times;
                </div>
                {!customer?.isLeader && (
                    <div className="bin">
                        <BinSymbolCommon onClick={handleRemoveCustomer} />
                    </div>
                )}
            </Header>
            <FormContainer>
                <Formik
                    initialValues={initialValues}
                    validationSchema={customerDetailSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);

                        if (!initialValues?.dateOfBirth) return;

                        const formattedDate = moment(values?.dateOfBirth).format("YYYY-MM-DD");
                        const newInfo = {
                            ...values,
                            dateOfBirth: formattedDate,
                            id: customer?.id || "",
                            isLeader: customer?.isLeader || false,
                            carts: customer?.carts || [],
                        };

                        setTimeout(() => {
                            dispatch(editCustomer({ id: customer?.id || "", newInfo }));
                            dispatch(checkPayment());
                        }, 300);

                        dispatch(
                            openToast({
                                message: "Update customer successfully!",
                                autoHideDuration: DURATION_TIME,
                            }),
                        );

                        if (onCloseCustomerDetail) {
                            onCloseCustomerDetail();
                        }
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setValues,
                        setFieldValue,
                        setTouched,
                    }) => {
                        const handleResetForm = () => {
                            setTouched(emptyTouched);
                            setValues(emptyValue);
                            setFieldValue("dateOfBirth", null);
                            handleResetCustomer();
                        };

                        useEffect(() => {
                            setFieldValue("dateOfBirth", dateValue);
                        }, [reRender]);

                        return (
                            <CustomerDetailFormComponent
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSubmit={handleSubmit}
                                isSubmitting={isSubmitting}
                                userId={customer?.id}
                                onResetCustomer={handleResetForm}
                                onRemoveCustomer={handleRemoveCustomer}
                                countriesData={countriesData}
                                dateValue={dateValue}
                                setFieldValue={setFieldValue}
                                handleChangeDate={handleChangeDate}
                                isLeader={customer?.isLeader}
                                isShowErrorDate={isShowErrorDate}
                            />
                        );
                    }}
                </Formik>
            </FormContainer>
        </Container>
    );
};

const Container = styled.div`
    border-radius: 15px 15px 0px 0px;
    overflow: hidden;
    height: 100%;
    background-color: #fff;

    ${breakpoint.breakTablet`
        border-radius: 0;
        margin-bottom: 30px;
    `}
`;

const Header = styled.div`
    height: 65px;
    background-color: ${(props) => props.theme.colors.main};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 28px;
    color: #fff;

    ${breakpoint.breakTablet`
        background-color: #fff;
        color: #000;
    `}

    .bin {
        display: none;
        cursor: pointer;

        ${breakpoint.breakTablet`
            display: block;
        `}
    }

    .heading {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
    }

    .closeBtn {
        font-size: 30px;

        ${breakpoint.breakTablet`
            display: none;
        `}
    }
`;

const FormContainer = styled.div`
    height: calc(100vh - 45px - 65px);
    overflow-y: scroll;
    padding-bottom: 90px;

    ${breakpoint.breakTablet`
        overflow-y: unset;
        padding-bottom: 44px;
        height: auto;
    `}
`;
