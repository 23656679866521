import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { StyledNavigator, SwitchCommon, IconCart, IconHome } from "@components";
import { PATH_CART, PATH_HOME } from "@routes";
import { AppDispatch, selectStatusApp, toggleStatus, useAppSelector } from "@redux";

interface IProps {
    onBack: () => void;
    onBackToHome: () => void;
    productCount: number;
}

export const HeaderNavigatorComponent = (props: IProps) => {
    const { onBack, productCount } = props;
    const isChecked = useAppSelector(selectStatusApp).status;
    const dispatch = useDispatch<AppDispatch>();
    const toggleStatusApp = ()=> {
        dispatch(toggleStatus());
    };
    return (
        <StyledNavigator>
            <button className="backBtn" onClick={onBack}>
                <img src="/images/fi_chevron-left.png" alt="chevDown" />
                <span>Back</span>
            </button>
            <div className="menu">
                <SwitchCommon isChecked={isChecked} setIsChecked={toggleStatusApp} />
                <Link to={PATH_CART} className="cartIconWrapper iconWrapper">
                    {productCount ? <span className="number">{productCount}</span> : <></>}
                    <IconCart />
                </Link>
                <Link className="iconWrapper" to={PATH_HOME}>
                    <IconHome />
                </Link>
            </div>
        </StyledNavigator>
    );
};
