import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";

import { breakpoint } from "@utilities";

interface IProps {
    isChecked?: boolean;
}

export const CheckBoxCommon = (props: IProps) => {
    const { isChecked } = props;

    return (
        <Container>
            <Checkbox
                checked={isChecked || false}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
            />
        </Container>
    );
};

const Container = styled.div`
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: ${(p) => p.theme.colors.main};
    }

    .PrivateSwitchBase-root-1 {
        padding: 5px;

        ${breakpoint.breakLarge`
            padding: 9px;
        `}
    }

    .MuiSvgIcon-root {
        width: 0.7em;
        height: 0.7em;

        ${breakpoint.breakLarge`
            width: 1em;
            height: 1em;
        `}
    }
`;
