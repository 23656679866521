import styled from "styled-components";
import parse from 'html-react-parser';

interface IProps {
    isActive: boolean;
    content: string;
}

export const NeedToKnowContentCommon = (props: IProps) => {
    const { isActive, content } = props;
    return (
        <Container
            className={`content ${isActive ? "active" : ""}`}
        >
            {parse(content)}
        </Container>
    );
};

const Container = styled.div`
    display: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: ${p => p.theme.colors.gray_2};

    &.active {
        display: block
    }
`;