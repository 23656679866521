import styled from "styled-components";
import { breakpoint } from "@utilities";

interface IProps {
    color?: string | string[];
    disabled?: boolean;
    icon?: string;
    textColor?: string;
    borderRadius?: string;
}

function backgroundColor(props: IProps) {
    if (props.disabled) {
        return "#BDBDBD"; // Solid color for disabled state
    }
    if (Array.isArray(props.color) && props.color.length > 1) {
        const gradientColors = props.color.join(", ");
        return `linear-gradient(90deg, ${gradientColors})`;
    } else if (props.color) {
        return props.color ?? "#31b4b9";
    }
}

function textColor(props: IProps) {
    return props.textColor ?? "#FFFFFF";
}

function borderRadius(props: IProps) {
    return props.borderRadius ?? "";
}

export const StyledButton = styled.button<IProps>`
    border: none;
    width: 100%;
    height: 54px;
    background: ${(props) => backgroundColor(props)};
    color: ${(props) => textColor(props)};
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    margin-top: 20px;
    border-radius: ${(props) => borderRadius(props)};
    gap: 8px;

    img {
        width: 24px;
        height: 24px;
    }

    ${breakpoint.breakTablet`
        font-size: 16px;
    `}

    ${breakpoint.breakIpadPro`
        font-size: 16px;
    `}
`;

export const StyledErrorButton = styled(StyledButton)`
    background: ${(props) => props.theme.colors.red};
`;

export const StyledBorderButton = styled(StyledButton)`
    background: #fff;
    border: ${(props) => `${props.theme.colors.main} 2px solid`};
    color: ${(props) => props.theme.colors.main};
    border-radius: 4px;
`;

export const StyledUnBackgroundButton = styled(StyledButton)`
    background-color: transparent;
    color: ${(p) => p.theme.colors.main};
`;

export const CancelButton = styled(StyledButton)`
    background-color: rgba(244, 63, 63, 0.1);
    color: ${(p) => p.theme.colors.red};
`;
