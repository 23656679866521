import Grid from "@mui/material/Grid2";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StyledDatePicker } from "../StyledComponents";

interface IProps {
    id?: string;
    label?: string;
    value: Date | null | undefined;
    onChange(date: Date | null, value?: string | null | undefined): void;
    limitDate?: {
        maxDate: Date | null;
        minDate: Date | null;
    };
    minDateMessage?: string;
    maxDateMessage?: string;
    placeholder?: string;
    name?: string;
}

export function DatePickerCommon(props: IProps) {
    const {
        id,
        label,
        value,
        onChange,
        limitDate,
        minDateMessage = "Date should not be before current date",
        maxDateMessage = "Date should not be in the future",
        placeholder = "Select Date",
    } = props;

    const minDate = limitDate?.minDate || new Date();
    const maxDate = limitDate?.maxDate || new Date("2100-01-01");

    return (
        <StyledDatePicker>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container direction="column" justifyContent="space-around">
                    <DatePicker
                        label={label}
                        value={value}
                        onChange={(date) => onChange(date, date ? date.toISOString() : null)}
                        minDate={minDate}
                        maxDate={maxDate}
                        slotProps={{
                            textField: {
                                id: id,
                                placeholder: placeholder,
                                helperText:
                                    value && value < minDate
                                        ? minDateMessage
                                        : value && value > maxDate
                                          ? maxDateMessage
                                          : "",
                            },
                        }}
                        format="dd/MM/yyyy"
                    />
                </Grid>
            </LocalizationProvider>
        </StyledDatePicker>
    );
}
