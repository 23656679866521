import { InputCommon, StyledButton, StyledForm, StyledForgotLink } from "@components";
import { ILogin } from "@interfaces";
import { IHandleChange, IHandleBlur, IHandleSubmit } from "@interfaces";
import { Divider } from "@mui/material";
import GoogleLoginButton from "../GoogleLoginButton";
import CustomAppleSignIn from "../AppleLoginButton";
// import CustomFacebookLoginButton from "../FacebookLoginButton";
// import { PATH_FORGOT_PASSWORD } from "@routes";

interface LoginFormProps {
    values: ILogin;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    isSubmitting: boolean;
    errors: { [key: string]: string };
    touched: { [key: string]: boolean };
}

export const LoginFormComponent = (props: LoginFormProps) => {
    const { values, handleChange, handleBlur, handleSubmit, isSubmitting,touched,errors} = props;

    const forgotPasswordLink = "/forgot-password";

    return (
        <StyledForm onSubmit={handleSubmit}>
            <InputCommon
                name="email"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.email}
                label="Email"
                type="text"
                error={touched.email && errors.email ? errors.email : ""}
            />
            <InputCommon
                name="password"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.password}
                label="Password"
                type="password"
                error={touched.password && errors.password ? errors.password : ""}
            />
            <StyledForgotLink>
                <a href={forgotPasswordLink}>Forgot password?</a>
            </StyledForgotLink>
            <StyledButton
                borderRadius="4px"
                color={["#436CB2 0%", "#28B7C3 100%"]}
                disabled={isSubmitting}
                type="submit"
            >
                Log In
            </StyledButton>
            <Divider
                style={{
                    color: "#898D8F",
                    marginTop: "22px",
                    marginBottom: "2px",
                    fontWeight: "bold",
                    fontSize: "12px",
                }}
            >
                OR
            </Divider>
            <CustomAppleSignIn/>
            <GoogleLoginButton/>
            {/* <CustomFacebookLoginButton/> */}
        </StyledForm>
    );
};
