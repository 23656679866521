import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { StyledBookingDetail, BookingDetailGeneralComponent, ClipLoaderCommon } from "@components";
import { BookingDetailItemModule } from "@module";
import { ssTokenAuthShare, useWindowDimensions } from "@utilities";
import { bookingApi } from "@api";
import { IBookingDetail } from "@interfaces";
import { selectStatusApp, useAppSelector } from "@redux";
import { PATH_HOME } from "@routes";

export const ShareBookingDetail = () => {
    //page state
    const [info, setInfo] = useState<IBookingDetail>();
    //page hooks
    const { width } = useWindowDimensions();
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    // lấy id của booking trên url rồi call để lấy data
    useEffect(() => {
        (async () => {
            try {
                const token = ssTokenAuthShare.getItem();
                const res = await bookingApi.getBookingDetailWithToken(id!, token);
                setInfo(res.data);
            } catch (err) {
                console.error(err);
                navigate(PATH_HOME);
            }
        })();
    }, []);
      // page redux
    const statusApp = useAppSelector(selectStatusApp).status;
    return (
        <StyledBookingDetail isShareBookingDetail={true} info={!! info}>
            {!info ? (
                <div className="clipLoader">
                    <div className="overlay"></div>
                    <ClipLoaderCommon color="#31b4b9" margin={20} size={80} />
                </div>
            ) : (
                <div className="wrapper">
                    <div className="detailWrapper">
                        <BookingDetailGeneralComponent bookingInfo={info} statusApp={statusApp} />
                        {width > 1024 ? <p className="heading1">Booking Detail</p> : ""}
                        <div className="listFare">
                            {width <= 1024 ? <p className="heading">Booking Detail</p> : ""}
                            {info.products.map((item, index) => {
                                return (
                                    <BookingDetailItemModule
                                        data={item}
                                        key={index}
                                        isShowCancel={false}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </StyledBookingDetail>
    );
};
