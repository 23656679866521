import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { PATH_HOME } from "@routes";
import { LoadingCommon } from "@components";

export const PaymentWrapper = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.search) {
            const params = window.location.search.substring(1);
            const paramsObj = queryString.parse(params);

            // Extract the URL and ensure it's a string
            const redirectUrl = Array.isArray(paramsObj.url) ? paramsObj.url[0] : paramsObj.url;

            if (redirectUrl) {
                window.location.href = redirectUrl;
            } else {
                navigate(PATH_HOME);
            }
        } else {
            navigate(PATH_HOME);
        }
    }, [navigate]);

    return <LoadingCommon />;
};
