import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { StyledButton } from "@components";
import { breakpoint, lsEmail } from "@utilities";
import { IProductsOfBooking } from "@interfaces";
import { useState } from "react";

interface IProps {
    item: IProductsOfBooking;
    qty: number | string;
    onTogglePopup: () => void;
    cancelDes: string;
    onCancelRequest: (
        voucherNumber: string,
        quantity: string,
        reason: string,
        supplierName: string,
        productName: string,
        fareName: string,
        cancelDes: string,
        cancelRules: {
            daysBeforeTravelDate: string;
            feeAmount: string;
        }[],
    ) => void;
    cancelRules: { daysBeforeTravelDate: string; feeAmount: string }[];
}

export const CancelPopupComponent = (props: IProps) => {
    const { qty, onTogglePopup, cancelDes, cancelRules, onCancelRequest, item } = props;

    const [send, setSend] = useState<boolean>(false);

    const [numberOfCancel, setNumberOfCancel] = useState<string>("");
    const [reasonCancel, setReasonCancel] = useState<string>("");
    const [errors, setErrors] = useState({ reason: "" });
    const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
    const agentEmail = lsEmail.getItem();

    const optionArray: string[] = [];

    if (typeof qty === "number") {
        for (let i = 1; i <= qty; i++) {
            optionArray.push(i < 10 ? "0" + i : "" + i);
        }
    } else if (typeof qty === "string") {
        const numericQty = parseInt(qty, 10);
        if (!isNaN(numericQty)) {
            for (let i = 1; i <= numericQty; i++) {
                optionArray.push(i < 10 ? "0" + i : "" + i);
            }
        }
    }

    const handleCancel = () => {
        onCancelRequest(
            item.voucherNumber,
            numberOfCancel,
            reasonCancel,
            item?.supplier?.name || "",
            item.name,
            item.fareName,
            cancelDes,
            cancelRules,
        );
        setSend(true);
    };

    const handleChangNumberOfCancel = (event: SelectChangeEvent<string>) => {
        setNumberOfCancel(event.target.value);
    };
    const handleCheckReasonForCancel = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 255) {
            setErrors({
                reason: "Reason cannot be more than 255 characters.",
            });
            return;
        } else {
            setErrors({ reason: "" });
        }
        setReasonCancel(event.target.value as string);
    };

    const handleConfirm = () => {
        setConfirmCancel(!confirmCancel);
    };

    return (
        <Container>
            <div className="overlay" onClick={onTogglePopup}></div>
            <div className="popup">
                {send ? (
                    <div className="thankyou">
                        <p className="title">Thank you for sending your cancellation request. </p>
                        <br />
                        <p>
                            Our team will endeavour to get back to you asap with your cancellation
                            details. Please note the refund amount is an estimate only and can vary
                            depending on Operators cancelation policy and discretion.
                        </p>
                        <p>You will shortly receive an email to {agentEmail} with more details.</p>
                        <br />
                        <p>
                            Please contact{" "}
                            <a href="mailto:support@freelancetravel.com">
                                support@freelancetravel.com
                            </a>{" "}
                            for further questions.
                        </p>
                        <div className="listBtn">
                            <div className="cancelBtn">
                                <StyledButton color="#F43F3F" onClick={onTogglePopup}>
                                    Close
                                </StyledButton>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <p className="title">{item.fareName}</p>
                        <p className="totalQty">
                            Total Quantity:{" "}
                            {typeof qty === "number"
                                ? qty < 10
                                    ? "0" + qty
                                    : "" + qty
                                : parseInt(qty, 10) < 10
                                  ? "0" + parseInt(qty, 10)
                                  : "" + parseInt(qty, 10)}
                        </p>

                        <div className="voucher_info">
                            {/* <h2 className="voucher_info--title">
                        The following cancellation fees will be deducted from any refund amount:
                    </h2> */}
                            <p className="voucher_info--text">
                                Please note any cancellation has a 10% cancellation processing PLUS
                                the operator fee bellow
                            </p>
                            <br />
                            <p className="voucher_info--text">{cancelDes}</p>
                            {cancelRules?.map((rule) => (
                                <p className="voucher_info--text">
                                    Cancelled {rule.daysBeforeTravelDate} days before travel:{" "}
                                    {rule.feeAmount}% fee
                                </p>
                            ))}
                        </div>
                        <SelectCT>
                            <InputLabel id="demo-simple-select-label">
                                Quantity to cancel
                            </InputLabel>
                            <div className="main">
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={numberOfCancel}
                                    onChange={handleChangNumberOfCancel}
                                >
                                    {optionArray.map((item, index) => {
                                        return (
                                            <MenuItem value={item} key={index}>
                                                {item}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <img src="/images/fi_chevron-down-dark.png" alt="" />
                            </div>
                        </SelectCT>
                        {/* <SelectCT>
                    <InputLabel id="demo-simple-select-label">Refund reason</InputLabel>
                    <div className="main">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={reasonCancel}
                            onChange={onChangeReasonCancel}
                        >
                            {reasonCancelList.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <img src="/images/fi_chevron-down-dark.png" alt="" />
                    </div>
                </SelectCT> */}
                        <TextFieldCT>
                            <label htmlFor="reasonForCancellation">Reason for cancellation</label>
                            <TextField
                                id="reasonForCancellation"
                                multiline
                                variant="filled"
                                onChange={handleCheckReasonForCancel}
                                value={reasonCancel}
                            />
                            <p className="errors">{errors.reason}</p>
                        </TextFieldCT>
                        <div className="confirm">
                            <Checkbox
                                color="default"
                                onChange={handleConfirm}
                                checked={confirmCancel}
                            />
                            <p className="confirm--content">
                                I have informed my customer of the cancellation fees that will be
                                deducted from their refund and they wish to proceed with cancelling
                                this booking.
                            </p>
                        </div>
                        <div className="listBtn">
                            <div className="cancelBtn">
                                <StyledButton color="#F43F3F" onClick={onTogglePopup}>
                                    Cancel
                                </StyledButton>
                            </div>
                            <div className="submitBtn">
                                <StyledButton
                                    disabled={!confirmCancel || numberOfCancel === ""}
                                    onClick={handleCancel}
                                >
                                    Submit
                                </StyledButton>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Container>
    );
};

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 0 16px;
    width: 100vw;
    height: 100vh;

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(9, 10, 24, 0.4);
    }

    .popup {
        background-color: #fff;
        padding: 0 17px;
        z-index: 1;
        max-height: 85vh !important;
        overflow-y: scroll !important;
        min-height: 15vh !important;

        ${breakpoint.breakTablet`
            position: absolute;
            width: 740px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0 65px;

        `}
        .thankyou {
            a {
                color: ${(props) => props.theme.colors.main};
            }
        }

        .voucher_info {
            padding: 15px 18px 15px 21px;
            background: rgba(244, 63, 63, 0.32);
            width: 100%;
            margin-top: 25px;
            color: rgba(244, 63, 63, 0.7);
            line-height: 22px;
            .voucher_info--title {
                font-weight: 600;
                line-height: 22px;
                margin-bottom: 18px;
            }
            .voucher_info--text {
                line-height: 21px;
            }
        }

        .title {
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
            color: ${(p) => p.theme.colors.black};
            margin-top: 45px;
        }

        .totalQty {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            margin-top: 9px;
        }

        .listBtn {
            display: flex;
            width: 100%;
            justify-content: stretch;
        }

        .cancelBtn,
        .submitBtn {
            flex: 1;

            button {
                margin-top: 38px;
                margin-bottom: 75px;
            }
        }

        .cancelBtn {
            margin-right: 20px;
        }
        .confirm {
            display: flex;
            align-items: center;
            margin-top: 30px;

            .MuiCheckbox-root {
                color: ${(p) => p.theme.colors.main};
            }

            .confirm--content {
                color: ${(p) => p.theme.colors.gray_3};
                font-size: 14px;
                line-height: 21px;
            }
            .confirm--content.colorMain {
                color: ${(props) => props.theme.colors.main};
            }
            .MuiButtonBase-root {
                padding-top: 0px;
                padding-left: 0px;
            }
        }
    }
    .popup::-webkit-scrollbar {
        -webkit-appearance: none;
        appearance: none;
        width: 3px;
    }

    .popup::-webkit-scrollbar-track {
        appearance: none;
        background-color: transparent;
    }

    .popup::-webkit-scrollbar-thumb {
        -webkit-appearance: none;
        appearance: none;
        background-color: rgba(0, 0, 0, 0.3);
        transition: 0.3s;
        border-radius: 1px;
    }

    .errors {
        color: red;
        margin-top: 8px;
    }
`;

const SelectCT = styled.div`
    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
        background-color: transparent;
    }

    .MuiFormLabel-root {
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: ${(p) => p.theme.colors.black};
        margin-top: 32px;
    }

    .MuiInput-root {
        width: 100%;
        background-color: ${(p) => p.theme.colors.gray_6};
    }

    .MuiSelect-select.MuiSelect-select {
        background-color: ${(p) => p.theme.colors.gray_6};
        padding: 11px 21px;
    }

    .MuiSelect-icon {
        display: none;
    }

    .MuiInput-underline:after {
        display: none;
    }

    .MuiInput-underline:before {
        display: none;
    }

    .main {
        position: relative;
        margin-top: 8px;

        img {
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
        }
    }
`;

const TextFieldCT = styled.div`
    label {
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        color: ${(p) => p.theme.colors.black};
        display: block;
        margin-top: 24px;
    }

    .MuiFilledInput-underline:before {
        display: none;
    }

    .MuiFilledInput-underline:after {
        display: none;
    }

    .MuiFormControl-root {
        width: 100%;
        margin-top: 8px;
    }

    .MuiFilledInput-root {
        min-height: 113px;
        display: flex;
        flex-direction: column;
        padding: 15px;
    }
`;
