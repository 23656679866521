import styled from "styled-components";
import { useDispatch } from "react-redux";
import { AppModuleLayout } from "@layouts";
import {
    PATH_BOOKING,
    PATH_FAVORITES,
    PATH_LINK_AGENT_ACCOUNT,
    PATH_MY_ACCOUNT,
    PATH_SAVED_CART,
    PATH_UPDATE_PASSWORD,
} from "@routes";
import { IModelAccountSetting } from "@interfaces";
import { IconUser, StyledItemAccount, StyledItemAccountLogout } from "@components";
import { AppDispatch, logout, resetSavedCart } from "@redux";

export const AccountSetting = () => {
    const model: IModelAccountSetting[] = [
        {
            iconComponent: <IconUser />,
            title: "Update My Details",
            link: PATH_MY_ACCOUNT,
        },
        {
            icon: "/images/userInfo/fi_heart.png",
            title: "My favourites",
            link: PATH_FAVORITES,
        },
        {
            icon: "/images/userInfo/fi_file-text.png",
            title: "Bookings",
            link: PATH_BOOKING,
        },
        {
            icon: "/images/userInfo/SAVE-CART.png",
            title: "Quotes",
            link: PATH_SAVED_CART,
        },
        {
            icon: "/images/userInfo/lock.png",
            title: "Update Password",
            link: PATH_UPDATE_PASSWORD,
        },
        {
            icon: "/images/userInfo/chain.svg",
            title: "Link Agent Account",
            link: PATH_LINK_AGENT_ACCOUNT,
        },
        {
            icon: "/images/userInfo/fi_log-out.png",
            title: "Logout",
            link: "",
        },
    ];

    const dispatch = useDispatch<AppDispatch>();

    const handleLogout = () => {
        dispatch(logout());
        dispatch(resetSavedCart());
    };

    return (
        <AppModuleLayout>
            <Wrapper>
                <div className="menu">
                    {model.map((item, index) => {
                        return item.title !== "Logout" ? (
                            <StyledItemAccount key={index} to={item.link}>
                                <div className="icon">
                                    {item.icon ? (
                                        <img src={item.icon} alt="" />
                                    ) : (
                                        item.iconComponent
                                    )}
                                </div>
                                <div className="text">{item.title}</div>
                            </StyledItemAccount>
                        ) : (
                            <StyledItemAccountLogout key={index} onClick={handleLogout}>
                                <div className="icon">
                                    <img src={item.icon} alt="" />
                                </div>
                                <div className="text">{item.title}</div>
                            </StyledItemAccountLogout>
                        );
                    })}
                </div>
            </Wrapper>
        </AppModuleLayout>
    );
};

const Wrapper = styled.div`
    padding: 40px;
    padding-bottom: 80px;

    .menu {
        & > a:first-child {
            padding-top: 0;
            padding-bottom: 32px;
        }

        & > div {
            border-bottom: none;
        }
    }
`;
