import { Formik } from "formik";
import { StyledLink, LoginLogo, SignUpEmailComponent, SignUpPasswordComponent } from "@components";
import { ISignUpEmail, ISignUpPassword } from "@interfaces";
import { signupEmailSchema, signupPasswordSchema } from "@validations";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, toggleLoading } from "@redux";
import { authApi } from "@api";
import { SuccessToast } from "src/Components/toasts/SuccessToast";
import { ErrorToast } from "src/Components/toasts/ErrorToast";

export const SignUp = () => {
    const EMAIL_SCREEN = "email-screen";
    const PASSWORD_SCREEN = "password-screen";
    const [screen, setScreen] = useState(EMAIL_SCREEN);
    const dispatch = useDispatch<AppDispatch>();
    const initialValuesEmail: ISignUpEmail = {
        email: "",
    };
    const [email, setEMail] = useState(initialValuesEmail.email);
    const navigate = useNavigate();
    const initialValuesPassword: ISignUpPassword = {
        password: "",
        ReEnterPassword: "",
    };

    return (
        <>
            <LoginLogo src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg" />
            {screen === EMAIL_SCREEN ? (
                <Formik
                    initialValues={initialValuesEmail}
                    validationSchema={signupEmailSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setScreen(PASSWORD_SCREEN);
                        setSubmitting(false);
                        setEMail(values.email);
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                        return (
                            <div style={{ width: "100%" }}>
                                <SignUpEmailComponent
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    handleSubmit={handleSubmit}
                                    isSubmitting={isSubmitting}
                                />
                            </div>
                        );
                    }}
                </Formik>
            ) : (
                <Formik
                    initialValues={initialValuesPassword}
                    validationSchema={signupPasswordSchema}
                    onSubmit={async (values) => {
                        const signUpData = {
                            email: email,
                            password: values.password,
                            ReEnterPassword: values.ReEnterPassword,
                        };
                        try {
                            dispatch(toggleLoading(true));
                            const res = await authApi.signUp(signUpData);
                            if (res.data.code === 200) {
                                SuccessToast({
                                    Message: "Signup successful! Redirecting to login...",
                                });
                                navigate("/login");
                            }
                        } catch (error: any) {
                            console.error("Error during signup:", {
                                status: error.response?.status,
                                data: error.response?.data,
                                message: error.message,
                            });
                            ErrorToast({
                                Message:
                                    error.response?.data?.data.email?.[0] ||
                                    "An error occurred during signup.",
                            });
                            setScreen(EMAIL_SCREEN);
                        } finally {
                            dispatch(toggleLoading(false));
                        }
                    }}
                >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <div style={{ width: "100%" }}>
                            <SignUpPasswordComponent
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleSubmit={handleSubmit}
                                isSubmitting={isSubmitting}
                            />
                        </div>
                    )}
                </Formik>
            )}
            <StyledLink>
                Already have an account? <NavLink to="/login">Sign in </NavLink>with your account
            </StyledLink>
        </>
    );
};
