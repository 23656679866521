import { BrowserRouter, useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { useEffect } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import { theme, storageVersion } from "@utilities";
import { AuthLayout, AppLayout } from "@layouts";
import {
    store,
    persistor,
    useAppSelector,
    toggleSplash,
    setHomepage,
    getPaymentMethod,
    selectSignIn,
    selectLoading,
    selectSplash,
    selectExternal,
    suppliers,
    // setAgentInfo,
    fetchFavoriteTour,
    useAppDispatch,
} from "@redux";
import {
    PATH_HOME,
    PATH_LOGIN,
    normalPath,
    authPath,
    // PATH_ACCOUNT,
    PATH_PAYMENT_FAIL,
} from "@routes";
import { LoadingCommon, SplashCommon } from "@components";
import { externalApi } from "@api";
// import { phpHelper } from "./Utilities/phpHelper";

function App() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <PersistGate loading={null} persistor={persistor}>
                    <div className="App ">
                        <BrowserRouter>
                            <Main />
                        </BrowserRouter>
                    </div>
                </PersistGate>
            </ThemeProvider>
        </Provider>
    );
}

function Main() {
    //hook
    const navigate = useNavigate();
    const location = useLocation();
    //redux state
    const dispatch = useAppDispatch();
    const { signIn } = useAppSelector(selectSignIn);
    const isLoading = useAppSelector(selectLoading).isLoading;
    const isDisplaySplash = useAppSelector(selectSplash).isSplash;
    const { time, date } = useAppSelector(selectExternal);
    //page variable
    const token = signIn?.data.accessToken;
    const currentPathName = location.pathname;
    let isNormalRoute = false;
    let isAuthRoute = false;
    // const prevPathname = ssPathname.getItem();

    // WHAT: check storage version, clear all data if have update about local storage
    const appVersion = storageVersion.getItem();
    const currentVersion = "2320061530";

    if (!appVersion || appVersion !== currentVersion) {
        localStorage.clear();
        localStorage.removeItem("persist:root");
        dispatch({ type: "RESET" });
        storageVersion.setItem(currentVersion);
    }
    const params = window.location.search.substring(1);
    const paramsObj = queryString.parse(params);

    //return old page if logout
    useEffect(() => {
        // WHAT: check normal path
        isNormalRoute =
            currentPathName === PATH_HOME
                ? true
                : normalPath.some((item: string) => currentPathName.includes(item));

        // WHAT: check a uth path
        isAuthRoute = authPath.some((item: string) => currentPathName.includes(item));

        // WHAT: check xem đã login lần nào chưa nếu chưa thì đẩy về màn term
        if (!token && isNormalRoute) {
            //check nếu payment lỗi khi chưa đăng nhập
            if (paramsObj.error) {
                navigate(PATH_PAYMENT_FAIL, paramsObj);
            } else {
                navigate(PATH_LOGIN);
            }
        }

        if (token && isAuthRoute) {
            navigate(PATH_HOME);
        }
    }, [currentPathName, token]);

    // WHAT: load splash first time
    useEffect(() => {
        (async () => {
            const newDate = new Date();

            // WHAT: update every day or first time
            if (!time || !date || (time < newDate.getTime() && date !== newDate.getDate())) {
                const data = await externalApi.getDataHomePage();
                dispatch(setHomepage(data.data));
            }
            const filter = null;
            // WHAT: dump params
            if (token) {
                // fetchAgent();
                // WHAT: load supplier filter
                await dispatch(suppliers({ filter }));
                //reload payment method
                await dispatch(getPaymentMethod());

                // WHAT: fetch favorite
                await dispatch(fetchFavoriteTour());
            }
            dispatch(toggleSplash(false));
        })();
    }, [token]);


    // this code is needed in case of authentication failure if it fails we need to redirect to the link agent account Pages

    // const fetchAgent = async () => {
    //     if (prevPathname) {
    //         ssPathname.removeItem();
    //         navigate(prevPathname);
    //     }

    //     const agentInfo = await phpHelper.getAccount();
    //     const agentData = agentInfo?.data?.json;
    //     if (agentData && agentData.firstName !== "") {
    //         dispatch(setAgentInfo(agentData));
    //     } else {
    //         navigate(PATH_ACCOUNT);
    //     }
    // };

    if (isDisplaySplash) {
        return <SplashCommon />;
    }

    return (
        <>
            {isLoading && <LoadingCommon />}
            {token ? <AppLayout /> : <AuthLayout />}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                closeButton={false}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}

export default App;
