import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./grid.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_OAUTH_CLIENT_ID } from "@constants";

// Create a root
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
// Render the app
root.render(
    <React.StrictMode>
        <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
            <App />
        </GoogleOAuthProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
