import { memo, useEffect } from "react";
import { NumericFormat, NumberFormatValues } from "react-number-format";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { StyledButton, StyledError } from "@components";
import { SHARE_OPTION } from "@constants";
import {
    AppDispatch,
    checkPayment,
    selectCart,
    // selectPaymentStep,
    selectStatusApp,
    setTotalPriceCart,
    useAppSelector
} from "@redux";
import { breakpoint } from "@utilities";
interface IProps {
    btnTitle?: string;
    onBtnClick?: () => void;
    page?: string;
    handleSaveCart?: () => void;
    paymentOption?: string;
}

export const TotalPriceComponent = memo((props: IProps) => {
    const { btnTitle, onBtnClick, page, handleSaveCart, paymentOption } = props;
    const dispatch = useDispatch<AppDispatch>();
    //redux state
    const cartRedux = useAppSelector(selectCart);
    const statusApp = useAppSelector(selectStatusApp).status;
    const {
        totalRRP,
        carts,
        totalNet,
        commission,
        commissionPercent,
        optionalFieldValidated,
        totalPrice,
        canCheckout,
    } = cartRedux;
    //page variable
    const availablePrice = totalPrice >= totalNet;
    const disablePayment =
        !availablePrice ||
        !optionalFieldValidated ||
        (page === "checkout" && !canCheckout) ||
        !cartRedux.carts.length;
    useEffect(() => {
        dispatch(checkPayment());
    }, []);

    const handleChangeTotalPrice = (values: NumberFormatValues) => {
        dispatch(setTotalPriceCart(values.floatValue || 0));
    };

    const totalItem = carts.reduce((total, cart) => {
        return total + cart.quantity;
    }, 0);

    const totalLevi = carts.reduce((total, cart) => {
        if (cart.levi) {
            return total + cart.levi * cart.quantity;
        } else {
            return total;
        }
    }, 0);

    return (
        <Price totalPrice={totalPrice.toFixed(2)}>
            <div className="wrapper">
                <table>
                    <tbody>
                        {page === "cart" && carts.length > 0 && (
                            <tr>
                                <td>
                                    <div className="field">Number of items:</div>
                                </td>
                                <td></td>
                                <td>
                                    <div className="value">{totalItem} Items</div>
                                </td>
                            </tr>
                        )}
                        {page !== "payment" && (
                            <tr>
                                <td>
                                    <div className="field">Total RRP</div>
                                </td>
                                <td></td>
                                <td>
                                    <div className="value">${totalRRP.toFixed(2)}</div>
                                </td>
                            </tr>
                        )}
                        {statusApp && (
                            <>
                                <tr>
                                    <td>
                                        <div className="field">Total Net</div>
                                    </td>
                                    <td></td>
                                    <td>
                                        <div className="value">${totalNet.toFixed(2)}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="field">Commission</div>
                                    </td>
                                    <td>
                                        <div className="commission">
                                            {Number.isInteger(commissionPercent)
                                                ? commissionPercent
                                                : commissionPercent?.toFixed(2) || 0}
                                            %
                                        </div>
                                    </td>
                                    <td>
                                        <div className="value commission">
                                            ${commission.toFixed(2)}
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
                {page !== "payment" ? <div className="line"></div> : <div className="dash"></div>}
                <div className="totalPrice">
                    <span className="text">Total Sell Price</span>
                    <span className="value">
                        <div className="value_input">
                            <NumericFormat
                                // displayType="text"
                                // customInput={TextField}
                                thousandSeparator={true}
                                value={totalPrice.toFixed(2)}
                                prefix={"$"}
                                onValueChange={handleChangeTotalPrice}
                                disabled={paymentOption === SHARE_OPTION}
                            />
                        </div>
                    </span>
                </div>
                {!availablePrice && (
                    <StyledError>
                        Error: The total sell price must be higher than the total net price
                    </StyledError>
                )}
                {page !== "payment" && (
                    // chỉnh lại logic của payment
                    <>
                    <ButtonCheckout disabled={disablePayment} onClick={onBtnClick}>
                        {btnTitle}
                    </ButtonCheckout>
                    {disablePayment && (
                        <StyledErrorCheckout>
                            Please fill in all mandatory fields before continuing
                        </StyledErrorCheckout>
                    )}
                    </>
                )}
                {/* chỉnh lại logic save quote */}
                {page === "checkout" && (
                    <ButtonSaveCart
                        disabled={!availablePrice || !canCheckout}
                        onClick={handleSaveCart}
                    >
                        Save Quote
                    </ButtonSaveCart>
                )}
                {page === "cart" && (
                    <p className="textFooter">
                        Total levies to pay on arrival ${totalLevi.toFixed(2)}
                    </p>
                )}
            </div>
        </Price>
    );
});

const Price = styled.div<{ totalPrice: string }>`
    flex: 1;
    display: flex;
    flex-direction: column;

    /* width: 310px; */
    ${breakpoint.breakIpad`
            width: 260px;
    `}
    .wrapper {
        background-color: #fff;
        padding: 20px 16px;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding-bottom: 12px;
            width: ${(p) => (p.totalPrice.length > 10 ? "315px" : "285px")};
        }
        ${breakpoint.breakLarge`
            width: 350px;
        `}
    }

    table {
        width: 100%;
        td {
            height: 34px;
            line-height: 34px;
        }
        .field,
        .value {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
        }

        .field {
            /* min-width: 189px; */
        }

        .value {
            text-align: right;
        }

        .commission {
            color: #f43f3f;
        }
    }

    .line {
        height: 1px;
        border-bottom: 1px solid #bdbdbd;
        margin: 20px 0;
    }
    .dash {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        border-top: 1px dashed ${(props) => props.theme.colors.gray_5};
        padding-top: 17px;
        margin: 17px -16px 0 -16px;
        position: relative;

        &::before,
        &::after {
            content: "";
            width: 20px;
            height: 20px;
            z-index: 1;
            position: absolute;
            background-color: #f2f2f2;
            border-radius: 50%;
            top: 0;

            ${breakpoint.breakTablet`
                    background-color: #F2F2F2;
                `}
        }

        &::before {
            left: 0;
            transform: translate(-50%, -50%);
        }

        &::after {
            right: 0;
            transform: translate(50%, -50%);
        }
    }

    .totalPrice {
        display: flex;
        justify-content: space-between !important;
        .text {
            font-size: 16px;
            font-weight: 500;
            line-height: 32px;
        }

        .value {
            font-size: 20px;
            font-weight: 700;
            line-height: 32px;
            color: ${(props) => props.theme.colors.main};
            background-color: #f2f2f2;
            display: flex;
            padding: 8px 10px 8px 40px;
            ${breakpoint.breakIpad`
                padding: 8px 10px 8px 30px;
                height:34px;
                display: flex;
                align-items: center;
            `}
            .value_input {
                display: inline-block;
                min-width: 61px;
                max-width: 150px;
                align-items: end;
                input {
                    max-width: 110px;
                    width: ${(p) => p.totalPrice.length * 13 + "px"};
                    color: ${(props) => props.theme.colors.main};
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }

        .textFooter {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            background-color: #fff;
            color: #4f4f4f;
            padding: 19px 16px 31px 16px;
            ${breakpoint.breakOnlyMobile`
                flex: 1;
            `}
        }

        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) and (max-width: ${(p) =>
                p.theme.breakPoints.breakLarge}) {
            flex: 350;

            table {
                .field {
                    min-width: 100px;
                }

                .field,
                .value {
                    font-size: 12px;
                }

                .commission {
                    font-size: 12px;
                    min-width: 44px;
                }
            }

            .line {
                margin: 10px 0;
            }

            .totalPrice {
                margin: 10px 0;

                .text,
                .value {
                    font-size: 13px;
                }
            }

            .textFooter {
                font-size: 12px;
                line-height: 19px;
                padding: 0px 16px 19px 16px;
            }
        }
    }
`;

const ButtonCheckout = styled(StyledButton)`
    margin-top: 18px;
    margin-bottom: 18px;
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) and (max-width: ${(p) =>
            p.theme.breakPoints.breakLarge}) {
        height: 35px;
    }
`;

const ButtonSaveCart = styled(ButtonCheckout)`
    color: ${(props) => !props.disabled && props.theme.colors.main} !important;
    background-color: ${(props) => !props.disabled && "#ffffff"};
    border: 2px solid ${(props) => !props.disabled && props.theme.colors.main};
`;

const StyledErrorCheckout = styled.div`
    margin-bottom:18px;
    text-align: left;
    color: ${(props) => props.theme.colors.red};
    font-size: 12px;
`;
