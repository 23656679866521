import styled from "styled-components";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { ITour } from "@interfaces";
import { breakpoint } from "@utilities";
import { TourElementModule } from "@module";
import { PATH_FAVORITES } from "@routes";

export interface IData {
    img: string;
    title: string;
    experiences: number;
}

interface IProps {
    data: ITour[];
    type?: string | null;
}

export const PromoTourModule = (props: IProps) => {
    const { data, type } = props;
    const sliderRef = useRef(null);
    const sliderWrapperRef = useRef(null);
    const navigate = useNavigate();

    const PREV_CONSTANT = "prev";
    const NEXT_CONSTANT = "next";

    const currentIndex = useRef(0);

    const slideToIndex = (index: number) => {
        if (window.innerWidth >= 1200) {
            // @ts-ignore
            sliderRef.current.style.transform = `translateX(-${index * 355.33333 + index * 25}px)`;
        } else if (window.innerWidth >= 1024) {
            // @ts-ignore
            sliderRef.current.style.transform = `translateX(-${index * 286 + index * 22}px)`;
        } else {
            // @ts-ignore
            sliderRef.current.style.transform = `translateX(-${index * 202 + index * 22}px)`;
        }
    };

    const handlePrevAndNext = (type: string) => {
        // @ts-ignore
        const style = getComputedStyle(sliderRef.current);
        const transformXValue = Number(style.transform.split(", ")[4]) || 0;
        if (window.innerWidth >= 1200) {
            if (type === NEXT_CONSTANT) {
                // @ts-ignore
                const limitNext = sliderRef.current.offsetWidth - 3 * (355.33333 + 25);
                if (transformXValue > 20 - limitNext && currentIndex.current < data.length - 3) {
                    currentIndex.current++;
                    slideToIndex(currentIndex.current);
                }
            } else if (type === PREV_CONSTANT) {
                if (transformXValue <= -10) {
                    currentIndex.current--;
                    slideToIndex(currentIndex.current);
                }
            }
        } else if (window.innerWidth >= 1024) {
            if (type === NEXT_CONSTANT) {
                // @ts-ignore
                const limitNext = sliderRef.current.offsetWidth - 3 * (286 + 22);
                if (transformXValue > 20 - limitNext && currentIndex.current < data.length - 3) {
                    currentIndex.current++;
                    slideToIndex(currentIndex.current);
                }
            } else if (type === PREV_CONSTANT) {
                if (transformXValue <= -10) {
                    currentIndex.current--;
                    slideToIndex(currentIndex.current);
                }
            }
        } else {
            if (type === NEXT_CONSTANT) {
                // @ts-ignore
                const limitNext = sliderRef.current.offsetWidth - 3 * (202 + 22);
                if (transformXValue > 20 - limitNext && currentIndex.current < data.length - 3) {
                    currentIndex.current++;
                    slideToIndex(currentIndex.current);
                }
            } else if (type === PREV_CONSTANT) {
                if (transformXValue <= -10) {
                    currentIndex.current--;
                    slideToIndex(currentIndex.current);
                }
            }
        }
    };

    const handleSeeAll = () => {
        if (type === "favorite") {
            navigate(PATH_FAVORITES);
        }
    };

    return (
        <Wrapper>
            {props.data.length > 3 ? (
                <button className="nextBtn" onClick={() => handlePrevAndNext(PREV_CONSTANT)}>
                    <img src="/images/website/destinations/fi_chevron-left.png" alt="prev" />
                </button>
            ) : (
                <></>
            )}
            <div className="sliderWrapper" ref={sliderWrapperRef}>
                {type && data.length > 3 ? (
                    <button className="seeAll" onClick={handleSeeAll}>
                        See all
                    </button>
                ) : (
                    <></>
                )}
                <div className="slider" ref={sliderRef}>
                    {data.map((tour) => {
                        return <TourElementModule data={tour} key={tour.productId} />;
                    })}
                </div>
            </div>
            {props.data.length > 3 ? (
                <button className="prevBtn" onClick={() => handlePrevAndNext(NEXT_CONSTANT)}>
                    <img src="/images/website/destinations/fi_chevron-right.png" alt="next" />
                </button>
            ) : (
                <></>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin: 0 auto;
    position: relative;
    z-index: 0;

    ${breakpoint.breakMobileMedium`
        width: ${(props) => props.theme.widths.wide_700};
    `}

    ${breakpoint.breakIpadPro`
        width: ${(props) => props.theme.widths.wide_900};
    `}

    ${breakpoint.breakLarge`
        width: ${(props) => props.theme.widths.wide_1110};
    `}

    .seeAll {
        position: absolute;
        right: 0;
        bottom: calc(100% + 36px);
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        color: ${(p) => p.theme.colors.main};
    }

    .sliderWrapper {
        overflow: hidden;
    }

    .slider {
        display: inline-flex;
        overflow: hidden;
        transition: 300ms;
        /* transform: translateX(-100px); */
    }

    .nextBtn {
        position: absolute;
        top: 50%;
        right: calc(100%);
        transform: translateY(-50%);
        height: 100px;
        width: 50px;
    }

    .prevBtn {
        position: absolute;
        top: 50%;
        left: calc(100%);
        transform: translateY(-50%);
        height: 100px;
        width: 50px;
    }
`;
