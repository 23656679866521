import { Link } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import {
    StyledHeader,
    SwitchCommon,
    IconCart,
    IconUser,
    MenuHeaderComponent,
    IconHeart,
    IconFileText,
    IconLock,
    IconChain,
} from "@components";
import {
    PATH_CART,
    PATH_HOME,
    PATH_FAVORITES,
    PATH_BOOKING,
    PATH_SAVED_CART,
    PATH_MY_ACCOUNT,
    PATH_UPDATE_PASSWORD,
    PATH_LINK_AGENT_ACCOUNT,
} from "@routes";
import { AppDispatch, selectStatusApp, toggleStatus, useAppSelector } from "@redux";

export interface IHeaderComponent {
    page?: string;
    isScrollUp?: boolean;
    headerFix?: boolean;
    toggleShowMenu: () => void;
    isShowMenu: boolean;
    productCount: number;
}

export const HeaderComponent = (props: IHeaderComponent) => {
    const { toggleShowMenu, isShowMenu, productCount } = props;
    const isChecked = useAppSelector(selectStatusApp).status;
    const dispatch = useDispatch<AppDispatch>();
    const toggleStatusApp = () => {
        dispatch(toggleStatus());
    };
    const listMenuItems = [
        {
            icon: IconUser,
            title: "Update My Details",
            link: PATH_MY_ACCOUNT,
        },
        {
            icon: IconHeart,
            title: "Favourites",
            link: PATH_FAVORITES,
        },
        {
            icon: IconFileText,
            title: "Bookings",
            link: PATH_BOOKING,
        },
        {
            icon: IconCart,
            title: "Quotes",
            link: PATH_SAVED_CART,
        },
        {
            icon: IconLock,
            title: "Update Password",
            link: PATH_UPDATE_PASSWORD,
        },
        {
            icon: IconChain,
            title: "Link Agent Account",
            link: PATH_LINK_AGENT_ACCOUNT,
        },
    ];

    return (
        <StyledHeader {...props}>
            <div className="backgroundHeader"></div>
            <div className="wrapper">
                <Link className="logo" to={PATH_HOME}>
                    <img
                        src="/images/logos/FreelanceTravel_Primary_Logo_Rev_(2)_(da3453ef-44bb-4946-a8ef-4ee1a8a33e3c).svg"
                        alt="logo"
                        className="logoItem logoWhite"
                    />
                    <img
                        src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg"
                        alt="logo"
                        className="logoItem logoColor"
                    />
                </Link>
                <div className="cart">
                    <div className="switchBtn">
                        <SwitchCommon
                            isChecked={isChecked}
                            color="#31b4b9"
                            setIsChecked={toggleStatusApp}
                        />
                    </div>
                    <div className="cartIconWrapper">
                        <Link to={PATH_CART}>
                            {productCount ? <span className="number">{productCount}</span> : <></>}
                            <IconCart />
                        </Link>
                    </div>
                    <div className="userIconWrapper" onClick={toggleShowMenu}>
                        <IconUser />
                        {isShowMenu ? <OverLay></OverLay> : <></>}
                        {isShowMenu ? <MenuHeaderComponent listMenuItems={listMenuItems} /> : <></>}
                    </div>
                </div>
            </div>
        </StyledHeader>
    );
};

const OverLay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
`;
