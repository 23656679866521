import styled from "styled-components";
import { Formik } from "formik";
import {
    BreadcrumbsCommon,
    LinkAgentComponent,
    MenuAccountComponent,
    StyledButton,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_HOME, PATH_LINK_AGENT_ACCOUNT } from "@routes";
import { breakpoint } from "@utilities";
import { agentloginSchema } from "@validations";
import { IAgentInfoV2, IAgentStatusResponse } from "@interfaces";
import { authApi } from "@api";
import { useEffect, useState } from "react";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { SuccessToast } from "src/Components/toasts/SuccessToast";

const AgentInfo = ({ label, data }: { label: string; data: string | JSX.Element | null }) => (
    <div className="agent-info">
        <p className="label">{label}</p>
        <p className="data">{data}</p>
    </div>
);

const AgentDetails = ({
    agentStatusData,
    onUnlink,
}: {
    agentStatusData: IAgentStatusResponse;
    onUnlink: () => void;
}) => {
    const [loading, setLoading] = useState(false);

    const agentInfoData = [
        { label: "Username", data: agentStatusData.data.username },
        { label: "Bank BSB", data: agentStatusData.data.bank_bsb },
        { label: "Bank Account", data: agentStatusData.data.bank_account },
        { label: "Bank Country Short Code", data: agentStatusData.data.bank_country_short_code },
        { label: "Linked On", data: agentStatusData.data.created_at },
        { label: "Status", data: <div>Active</div> },
    ];

    const handleUnlinkAgent = async () => {
        setLoading(true);
        try {
            await authApi.unLinkAgent();
            onUnlink();
        } catch (error) {
            ErrorToast({ Message: `Error unlinking agent: ${error}` });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <p className="text-tag">TDMS Detail</p>
            <div className="agent-card">
                {agentInfoData.map((item, index) => (
                    <AgentInfo key={index} label={item.label} data={item.data} />
                ))}
            </div>
            <StyledButton
                color={["#436CB2 0%", "#28B7C3 100%"]}
                className="submit_account"
                type="button"
                borderRadius="4px"
                onClick={handleUnlinkAgent}
                disabled={loading}
            >
                Unlink
            </StyledButton>
        </Container>
    );
};

const AgentLoginForm = ({
    initialValues,
    onLogin,
}: {
    initialValues: IAgentInfoV2;
    onLogin: (data: IAgentStatusResponse) => void;
}) => (
    <Formik
        initialValues={initialValues}
        validationSchema={agentloginSchema}
        onSubmit={async (values, { setSubmitting }) => {
            try {
                const AgentData = await authApi.agentLogin(values);
                if (AgentData.data.access_token) {
                    const data = { ...AgentData.data, username: values.username };
                    const res = await authApi.agentLoginAdd(data);
                    if (res.data) {
                        SuccessToast({ Message: "Agent Linked Successfully" });
                    }
                    onLogin(res.data);
                }
            } catch (error: any) {
                if (error.response?.data?.data?.username?.[0]) {
                    ErrorToast({
                        Message: `Error logging in agent: ${error.response.data.data.username?.[0]}`,
                    });
                }else{
                    ErrorToast({Message:error.response.data.message});
                }
            } finally {
                setSubmitting(false);
            }
        }}
    >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <LinkAgentComponent
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
            />
        )}
    </Formik>
);

export const LinkAgentAccount = () => {
    const breadcrumbsData = [
        { title: "Home", link: PATH_HOME },
        { title: "Link Agent Account", link: PATH_LINK_AGENT_ACCOUNT },
    ];

    const [agentStatusData, setAgentStatusData] = useState<IAgentStatusResponse | null>(null);

    const initialValues: IAgentInfoV2 = {
        username: "",
        password: "",
    };

    useEffect(() => {
        const fetchTokenInfo = async () => {
            try {
                const tokenInfoAuth = await authApi.agentInfo();
                setAgentStatusData(tokenInfoAuth.data);
            } catch (err) {
                console.error("Error fetching agent info:", err);
            }
        };

        fetchTokenInfo();
    }, []);

    const handleLogin = (data: IAgentStatusResponse) => {
        setAgentStatusData(data); // Set the agent data to display AgentDetails
    };

    const handleUnlink = () => {
        setAgentStatusData(null); // Reset to show login form
        SuccessToast({ Message: "Agent Unlinked Successfully" });
    };

    return (
        <AppModuleLayout>
            <StyledMyAccountPage>
                <div className="wrapper">
                    <div className="breadCrumbs">
                        <BreadcrumbsCommon data={breadcrumbsData} />
                    </div>
                    <div className="menu">
                        <MenuAccountComponent />
                    </div>
                    <div className="my_account">
                        <p className="heading1">Link Agent Account</p>
                        <FormContainer>
                            {agentStatusData ? (
                                <AgentDetails
                                    agentStatusData={agentStatusData}
                                    onUnlink={handleUnlink}
                                />
                            ) : (
                                <AgentLoginForm
                                    initialValues={initialValues}
                                    onLogin={handleLogin}
                                />
                            )}
                        </FormContainer>
                    </div>
                </div>
            </StyledMyAccountPage>
        </AppModuleLayout>
    );
};

const StyledMyAccountPage = styled.div`
    background-color: #f2f2f2;
    min-height: calc(100vh - 95px);
    ${breakpoint.breakOnlyMobile`
    background-color:#fff;
    `}

    .wrapper {
        display: block;
        margin: 0 auto;
        position: relative;

        ${breakpoint.breakTablet`
        display: flex;
        width: ${(p) => p.theme.widths.wide_700};
        margin-top: 81px;
        padding-bottom: 20px;
    `}

        ${breakpoint.breakIpadPro`
        width: ${(p) => p.theme.widths.wide_900};
    `}

    ${breakpoint.breakLarge`
        width: ${(p) => p.theme.widths.wide_1110};
    `}

    .breadCrumbs {
            position: absolute;
            bottom: calc(100% + 30px);
            display: none;

            ${breakpoint.breakTablet`
            display: block;
        `}
        }

        .menu {
            display: none;

            ${breakpoint.breakTablet`
            display: block;
        `}
        }
    }

    .my_account {
        padding: 24px 16px;
        background-color: #f2f2f2;
        position: relative;
        top: 11px;
        margin-bottom: 111px;
        ${breakpoint.breakOnlyMobile`
         background-color:#fff;
       `}

        ${breakpoint.breakTablet`
        flex: 1;
        padding: 0;
    `}

        .heading1 {
            font-size: 28px;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 26px;
            margin-top: -10px;
            ${breakpoint.breakTablet`
            display: block;
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
        `}
        }
    }
`;

const FormContainer = styled.div`
    background-color: #fff;
    padding: 32px 32px 45px 32px;
    ${breakpoint.breakOnlyMobile`
    background-color: transparent;
    padding: 0px;
      
    `}
`;

const Container = styled.form`
    .submit_account {
        width: 32.5%;
        margin: auto;
        margin-top: 50px;
        ${breakpoint.breakOnlyMobile`
        width: 100%;
        margin-top: 30px;
        `};
    }
    .text-tag {
        font-weight: 600;
        margin-bottom: 30px;
        margin-top: 20px;
    }
    .agent-card {
        padding: 14px 0;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        color: #090a18;
        .agent-info {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            &:not(:last-child) {
                border-bottom: 1px solid #c5c7c9; /* Divider after each row except the last one */
            }
            .label {
                width: 30%;
            }
            .data {
                width: 70%;
                text-align: right;
            }
        }
    }
`;
