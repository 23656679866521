import FormControl from "@mui/material/FormControl";
import styled from "styled-components";

export const StyledFormControl = styled(FormControl)`
    margin-top: 15px !important;
    width: 100%;
    label {
        color: ${(props) => props.theme.colors.gray_2};
        line-height: 160%;
        font-weight: 100;
        font-size: 16px;
        font-family: DM Sans !important;
    }
    img {
        position: relative;
        top:6px;
        width: 19px;
        height: 19px;
        cursor: pointer;
    }
    .MuiInputBase-input {
        padding: 6px 0 12px !important;
    }

    .MuiInput-underline {
        &:before {
            border-color: ${(props) => props.theme.colors.sub_2};
        }
        &:after {
            border-bottom: 1px solid ${(props) => props.theme.colors.main};
        }
    }
    .MuiFormLabel-root.Mui-focused {
        color: ${(props) => props.theme.colors.main};
        font-weight: 600;
    }
    .MuiInputLabel-shrink {
        font-weight: 600;
    }
    
    .MuiInputBase-input.MuiInput-input[type="date"]::-webkit-calendar-picker-indicator {
        background-image: url("/images/fi_calendar.png");
        z-index: 1;
    }

    .MuiFormControl-marginNormal {
        margin-top: 0 !important;
    }
`;
