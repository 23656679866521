import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
// import InfiniteScroll from "react-infinite-scroll-component";
import queryString from 'query-string';

import {
    DatePickerCommon,
    FareOptionComponent,
    ProductDetailComponent,
    StyledBorderButton,
    StyledButton,
    StyledPopupCheckAvail,
    StyledRatio34Image,
    ClipLoaderCommon,
} from "@components";
import { AppModuleLayout } from "@layouts";
import { diffDate, useWindowDimensions } from "@utilities";
import {
    PATH_CART,
    PATH_CHECK_AVAILABILITY,
    PATH_DETAIL_TOUR,
    PATH_HOME,
    PATH_SEARCH_RESULT,
} from "@routes";
import { ICheckAvail, IFareAvail, IAddCart, IDateRange } from "@interfaces";
import { CheckAvailTableModule } from "@module";
import {
    addToCart,
    getTour,
    openToast,
    selectTour,
    setDateRangeFrom,
    setDateRangeTo,
    useAppSelector,
    setStep,
    setCurrentStep,
    resetPickupTimes,
    setSupplier,
    useAppDispatch,
} from "@redux";
import { toursApi } from "@api";
import { DURATION_TIME } from "@constants";

export const CHANGE_DATE_FROM = "FROM";
export const CHANGE_DATE_TO = "TO";

export const CheckAvailability = () => {
    //page hook
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = queryString.parse(window.location.search) as unknown as IFareAvail;
    //redux state
    const tourRedux = useAppSelector(selectTour);
    //page state
    const [checkAvailData, setCheckAvailData] = useState<ICheckAvail[]>([]);
    const [pagingCheckAvailData, setPagingCheckAvailData] = useState<ICheckAvail[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    const [date, setDate] = useState<IDateRange>({
        from: params.from || null,
        to: params.to || null,
    });
    //page variable
    const { width } = useWindowDimensions();
    const isChoseDate = useRef({ from: false, to: false });
    const { id } = useParams<{ id: string }>();
    const tour = tourRedux.tour;
    const mainRef = useRef<any>(null);

    useEffect(() => {
        if (!params.bookingTime || !params.productPricesDetailsId) {
            navigate(PATH_DETAIL_TOUR + "/" + id);
        }
    }, [params]);

    //get tour detail
    useEffect(() => {
        if (id !== tour?.productId) dispatch(getTour(id!));
    }, [id]);

    useEffect(() => {
        if (mainRef.current) {
            mainRef.current.scrollIntoView();
        }
    }, []);

    useEffect(() => {
        if (!date.from || !date.to) {
            return;
        }
        const _diffDate = diffDate(date.from, date.to);
        if (_diffDate >= 0) {
            setCheckAvailData([]);
            setIsLoading(true);
            toursApi
                .checkAvail({ ...params, ...date })
                .then((res: any) => {
                    const dataProcess = res.data.map((item: any) => {
                        return {
                            ...item,
                        };
                    });
                    setCheckAvailData(dataProcess);
                    setPagingCheckAvailData(
                        dataProcess.filter((item: ICheckAvail) => item.NumAvailable > 0)
                    );
                })
                .catch((err: any) => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                })
                .finally(() => setIsLoading(false));
        } else {
            dispatch(
                openToast({
                    type: "error",
                    autoHideDuration: DURATION_TIME,
                    message: "Invalid date.",
                })
            );
        }
    }, [date]);

    // useEffect(() => {
    //     setPagingCheckAvailData(checkAvailData.slice(0, PAGINATION));
    // }, [checkAvailData]);

    const breadcrumbsData = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Search result",
            link: PATH_SEARCH_RESULT,
        },
        {
            title: "Detail tour",
            link: PATH_DETAIL_TOUR + "/" + id,
        },
        {
            title: "Check Availability",
            link: PATH_CHECK_AVAILABILITY + "/" + id + window.location.search,
        },
    ];

    const handleToggleShowPopup = () => {
        if (!isShowPopup) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "overlay";
        }
        setIsShowPopup(!isShowPopup);
    };

    // const maxDate = dateRange.from ? getRelativeDate(new Date(dateRange.from), 13, 1) : null;
    // const minDate = dateRange.to ? getRelativeDate(new Date(dateRange.to), 13, 0) : null;

    const now = new Date();
    const travelStart = params.minDate ? new Date(params.minDate) : now;
    const minDate = travelStart > now ? travelStart : now;
    const maxDate = params.maxDate ? new Date(params.maxDate) : null;

    const limitDate = {
        from: {
            minDate, // không được chọn ngày nhỏ hơn ngoài khoảng 14 ngày
            maxDate: date.to || maxDate, // không được chọn ngày lớn hơn toDate
        },
        to: {
            minDate: date.from || minDate,
            maxDate: maxDate,
        },
    };

    // console.log(useAppSelector((state: RootState) => state.cart));

    const handleAddToCart = (tourFare: ICheckAvail) => {
        if (tour) {
            const newCartItem: IAddCart = {
                tour: tour,
                checkedAvail: { ...tourFare },
                fareName: params.fareName,
                bookingTimeId: params.bookingTime,
                productPricesDetailsId: params.productPricesDetailsId,
                datePriceCacheId: tourFare.datePriceCacheId,
            };
            dispatch(addToCart(newCartItem));
        }
    };

    const handleGoToCart = () => {
        document.body.style.overflowY = "";
        navigate(`${PATH_CART}`);
        // set lại date form và date to về null
        dispatch(setDateRangeFrom(null));
        dispatch(setDateRangeTo(null));
        // reset lại pickup time
        dispatch(resetPickupTimes());
        // set lại step và currentStep
        dispatch(setStep(1));
        dispatch(setCurrentStep(1));
    };

    // const handleLoadMoreMobile = () => {
    //     if (pagingCheckAvailData.length === checkAvailData.length) {
    //         return;
    //     }
    //     setPagingCheckAvailData([
    //         ...pagingCheckAvailData,
    //         ...checkAvailData.slice(pagingCheckAvailData.length, pagingCheckAvailData.length + 10),
    //     ]);
    // };

    const handleGotoHome = () => {
        document.body.style.overflowY = "";
        navigate(PATH_HOME);
        dispatch(setStep(1));
    };

    const changeDateFrom = useCallback(
        (dateCheck: Date) => {
            setDate({ to: date.to, from: dateCheck });
        },
        [date]
    );

    const changeDateTo = useCallback(
        (dateCheck: Date) => {
            setDate({ from: date.from, to: dateCheck });
        },
        [date]
    );

    const handleClickSupplier = () => {
        navigate(PATH_SEARCH_RESULT);
        dispatch(
            setSupplier({
                id: Number(tourRedux.tour?.supplierId),
                text: tourRedux.tour?.supplier,
            })
        );
    };

    return (
        <AppModuleLayout>
            <Container>
                {tour &&
                    (width < 767 ? (
                        <Main>
                            <StyledRatio34Image img={tour.productImagePath} />
                            <Info>
                                <h1 className="titleProduct">{tour.name}</h1>
                                <h3 className="fareName">{params.fareName}</h3>
                                <div className="date">
                                    <div className="from">
                                        <DatePickerCommon
                                            label="From"
                                            id="from"
                                            value={date.from}
                                            onChange={changeDateFrom}
                                            limitDate={limitDate.from}
                                        />
                                    </div>
                                    <div className="to">
                                        <DatePickerCommon
                                            label="To"
                                            id="to"
                                            value={date.to}
                                            onChange={changeDateTo}
                                            limitDate={limitDate.to}
                                        />
                                    </div>
                                </div>
                            </Info>
                            <div className="listOption">
                                {!pagingCheckAvailData.length ? (
                                    !isLoading ? (
                                        <p style={{ textAlign: "center" }}>
                                            There is no availability for this fare. Please change
                                            your date or select another fare.
                                        </p>
                                    ) : (
                                        <div className="clip-loader">
                                            <ClipLoaderCommon
                                                color="#31B4B9"
                                                size={70}
                                                margin={10}
                                            />
                                        </div>
                                    )
                                ) : (
                                    pagingCheckAvailData.map((item, index) => {
                                        return (
                                            <FareOptionComponent
                                                key={index}
                                                data={item}
                                                onShowPopup={handleToggleShowPopup}
                                                onAddToCart={handleAddToCart}
                                            />
                                        );
                                    })
                                )}
                                {/* <InfiniteScroll
                                    dataLength={pagingCheckAvailData.length}
                                    next={handleLoadMoreMobile}
                                    hasMore={true}
                                    loader={<></>}
                                > */}
                                {/* {pagingCheckAvailData.map((item, index) => {
                                    return (
                                        <FareOptionComponent
                                            key={index}
                                            data={item}
                                            onShowPopup={handleToggleShowPopup}
                                            onAddToCart={handleAddToCart}
                                        />
                                    );
                                })} */}
                                {/* </InfiniteScroll> */}
                            </div>
                        </Main>
                    ) : (
                        <CheckAvailDesktopWrapper>
                            <ProductDetailComponent
                                breadcrumbsData={breadcrumbsData}
                                data={tour}
                                handleClickSupplier={handleClickSupplier}
                            />
                            <Main>
                                <CheckAvailTableModule
                                    //@ts-ignore
                                    ref={mainRef}
                                    data={checkAvailData}
                                    fareName={params.fareName}
                                    params={params}
                                    date={date}
                                    changeDateFrom={changeDateFrom}
                                    changeDateTo={changeDateTo}
                                    limitDate={limitDate}
                                    isLoading={isLoading}
                                    onTogglePopup={handleToggleShowPopup}
                                    isChoseDate={isChoseDate.current}
                                />
                            </Main>
                        </CheckAvailDesktopWrapper>
                    ))}
                {isShowPopup && (
                    <StyledPopupCheckAvail>
                        <div className="overlay"></div>
                        <div className="main">
                            <p className="fareName">{params.fareName}</p>
                            <p className="fareName line2">is added to your cart</p>
                            <StyledButton onClick={handleGoToCart}>Go to cart</StyledButton>
                            <StyledBorderButton onClick={handleGotoHome}>
                                Continue shopping
                            </StyledBorderButton>
                        </div>
                    </StyledPopupCheckAvail>
                )}
            </Container>
        </AppModuleLayout>
    );
};

const Main = styled.div`
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    flex: 1;

    .listOption {
        width: 100%;
        flex: 1;
        padding: 18px 16px;

        .clip-loader {
            width: 100%;
        }
    }
`;

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const CheckAvailDesktopWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Info = styled.div`
    padding: 16px 16px 21px 16px;
    background-color: #fff;

    .MuiIconButton-label {
        color: ${(props) => props.theme.colors.main};
    }

    .titleProduct {
        font-weight: 700;
        font-size: 18px;
        color: ${(props) => props.theme.colors.black};
    }

    .fareName {
        margin-top: 10px;
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 18px;
        border-bottom: ${(props) => `1px solid ${props.theme.colors.gray_5}`};
    }

    .date {
        display: flex;
        margin-top: 14px;

        .from {
            margin-right: 16px;
        }
    }
`;
