import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import {
    authPath,
    auth_route,
    PATH_SHARE_BOOKING_DETAIL,
    PATH_SHARE_ORDER_SUCCESS,
    PATH_TERM,
    PATH_FORGOT_PASSWORD,
} from "@routes";
import { IRoute } from "@interfaces";
import { breakpoint, storageVersion } from "@utilities";
import { StyledVersion } from "@components";

interface ILoginFormContainerProps {
    isTermLayout: boolean;
    isNotFound: boolean;
    isShareSuccess: boolean;
    isShareBookingDetail: boolean;
    isForgotPassword: boolean; // Add this prop
}

const appVersion = storageVersion.getItem();

export const AuthLayout = () => {
    const pathName = location.pathname;
    const isNotFound = authPath.some((item: string) => pathName === item);
    const isShareBookingDetail = pathName.includes(PATH_SHARE_BOOKING_DETAIL);
    const isForgotPassword = pathName.includes(PATH_FORGOT_PASSWORD);

    return (
        <Container>
            <Content
                isShareBookingDetail={isShareBookingDetail}
                isShareSuccess={pathName === PATH_SHARE_ORDER_SUCCESS}
                isForgotPassword={isForgotPassword} // Pass the isForgotPassword prop
            >
                {isForgotPassword ? (
                    <BgImage isForgotPassword={isForgotPassword} />
                ) : (
                    <DefaultLayout />
                )}
                <LoginFormContainer
                    isTermLayout={pathName === PATH_TERM}
                    isNotFound={isNotFound}
                    isShareSuccess={pathName === PATH_SHARE_ORDER_SUCCESS}
                    isShareBookingDetail={isShareBookingDetail}
                    isForgotPassword={isForgotPassword} // Pass the isForgotPassword prop
                >
                    <Routes>
                        {auth_route.map((route: IRoute, key) => (
                            <Route
                                key={key}
                                path={route.path}
                                element={<route.element />} // Ensure element is JSX
                            />
                        ))}
                    </Routes>
                    
                </LoginFormContainer>
                        {isForgotPassword && (
                            <MobileBgImage src="images/BgImage2.jpg" alt="bg" />
                        )}

                {!isForgotPassword && (
                    <div className="version">
                        <StyledVersion>{appVersion}</StyledVersion>
                    </div>
                )}
            </Content>
        </Container>
    );
};

const LoginFormContainer = styled.div.withConfig({
    shouldForwardProp: (prop) =>
        ![
            "isTermLayout",
            "isNotFound",
            "isShareSuccess",
            "isShareBookingDetail",
            "isForgotPassword",
        ].includes(prop),
})<ILoginFormContainerProps>`
    background-color: ${(p) => (p.isShareSuccess ? "rgba(255, 255, 255, 0.9)" : "#ffffff")};
    margin: ${(p) => (!p.isNotFound ? "0px" : "15px")};
    margin: ${(p) => (p.isForgotPassword ? "0px" : "15px")};
    padding: 64px 15px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height:100vh

    ${breakpoint.breakOnlyMobile`
            min-width: 345px; 
            max-height: inherit;
            
        `}
    ${breakpoint.breakMobileMedium`
            min-width: 430px;    
        `}

        @media (min-width: ${(props) => props.theme.breakPoints.breakTablet}) {
        padding: ${(p) => (p.isShareBookingDetail ? "0 45px" : "64px 45px")};
        padding: ${(p) => (p.isForgotPassword ? "0px" : "64px 45px")};
        width: ${(p) => (p.isTermLayout ? "608px" : "unset")};
        width: ${(p) => (p.isForgotPassword ? "50%" : "unset")};
        height: ${(p) => (p.isForgotPassword ? "100%" : "unset")};
    }

    @media (min-width: ${(props) => props.theme.breakPoints.breakLarge}) {
        padding: ${(p) => (p.isShareBookingDetail ? "0 57px" : "40px 95px")};
        padding: ${(p) => (p.isForgotPassword ? "0px" : "40px 95px")};
        width: ${(p) => (p.isTermLayout ? "1050px" : "unset")};
        width: ${(p) => (p.isForgotPassword ? "50%" : "unset")};
        height: ${(p) => (p.isForgotPassword ? "100%" : "unset")};
    }

    @media (max-width: 321px) {
        min-width: 290px;
    }
`;

const Container = styled.section`
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const Content = styled.div.withConfig({
    shouldForwardProp: (prop) =>
        !["isShareBookingDetail", "isShareSuccess", "isForgotPassword"].includes(prop),
})<{ isShareBookingDetail: boolean; isShareSuccess?: boolean; isForgotPassword: boolean }>`
    position: relative;
    box-sizing: border-box;
    ${breakpoint.breakOnlyMobile`
            justify-content : center
        `}
    display: ${(p) => (!p.isForgotPassword ? "flex" : "unset")};
    justify-content: ${(p) =>
        p.isForgotPassword ? "flex-start" : "center"}; // Align items to start for forgot password
    min-height: 100vh;
    align-items: center;
    flex-direction: ${(p) =>
        p.isForgotPassword ? "row" : "column"}; // Use row for forgot password
    height: 100%;
    overflow: ${(p) => (p.isShareSuccess ? "hidden" : "inherit")};

    @media (max-width: ${(props) => props.theme.breakPoints.breakTablet}) {
        align-items: ${(p) => (p.isShareBookingDetail ? "inherit" : "center")};
    }
    .version {
        position: fixed;    
        bottom: 0px;
    }
`;

const MobileBgImage = styled.img`
    display: none;
    width: 100%;
    margin-top: -20px;

    ${breakpoint.breakOnlyMobile`
        display : block;
    `}
`;

const BgImage = styled.div<{ isForgotPassword: boolean }>`
    ${breakpoint.breakOnlyMobile`
        width: 100%
    `}
    width: 50%; /* Take the other half of the screen width */
    height: 100%;
    background-position: end;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${(props) =>
        props.isForgotPassword ? "/images/PasswordBgImage.png" : "/images/login-background.png"});
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
`;

const DefaultLayout = styled.div`
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("/images/login-background.png");
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
`;
