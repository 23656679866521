import styled from "styled-components";
import { ITag } from "./index";
import { NavLink } from "react-router-dom";

const FooterTag = (props: ITag) => {
    const { Icon, text, link, active } = props;

    //@ts-ignore
    const IconTag = () => <Icon />;

    return (
        <StyledNavLink to={link} className={active ? "active" : ""}>
            <IconTag />
            <span>{text}</span>
        </StyledNavLink>
    );
};

const StyledNavLink = styled(NavLink)`
    display: flex;
    width: 100%;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &.active {
        background-color: #31b4b9;
        color: white;
        svg {
            path {
                stroke: white;
            }
        }
    }
    span {
        margin-top: 8px;
    }

    &:hover {
        background-color: #e0e0e0;
    }
`;

export default FooterTag;
