import { logger } from "@utilities";
import axios from "axios";
import queryString from 'query-string';
import { store, logout, resetSavedCart } from "@redux";
import { normalPath } from "./../Routes/index";
import { PATH_HOME } from "@routes";
import { ssPathname } from "./../Utilities/storage";

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,

    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },

    paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use((config) => {
    const token = store.getState().auth.auth?.access_token;
    config.headers.Authorization = `Bearer ${token}`;

    // const token = await getFirebaseToken();
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }

    return config;
});

axiosClient.interceptors.response.use(
    (response) => {
        if (response && response.data) {
            return response;
        }
        return response;
    },

    (error: any) => {
        // Handle errors
        if (error.response && error.response.status === 401) {
            const current = window.location.pathname;
            const isNormalRoute =
                current === PATH_HOME
                    ? true
                    : normalPath.some((item: string) => current.includes(item));
            if (isNormalRoute) ssPathname.setItem(current + window.location.search);

            store.dispatch(logout());
            store.dispatch(resetSavedCart());
        }
        if (error.response && error.response.status !== 404 && error.response.status !== 401) {
            logger.apiError(window.location.href, error.response);
        }
        throw error;
    }
);

export default axiosClient;
