import parse from 'html-react-parser';
import { memo } from "react";

import { PATH_FAVORITES, PATH_SEARCH_RESULT } from "@routes";
import { ITourElement } from "@interfaces";
import {
    HeartCommon,
    IconMapPin,
    IconClock,
    StyledTourElementMain,
    StyledTourElementSearch,
    StyledTourElement,
} from "@components";
import { useWindowDimensions, sliceString } from "@utilities";
import {
    FAVORITE_METHOD_LIKE,
    FAVORITE_METHOD_UNLIKE,
    selectFavoriteTours,
    toggleFavorites,
    useAppDispatch,
    useAppSelector,
} from "@redux";

interface IProps {
    data: ITourElement;
}

export const TourElementModule = memo((props: IProps) => {
    const pathname = window.location.pathname;
    const { width } = useWindowDimensions();
    const favoritesTours = useAppSelector(selectFavoriteTours).tours;
    const { productImagePath, name, location, duration, rrp, description, supplier } = props.data;
    const isLiked = favoritesTours.some((tour) => tour.productId === props.data.productId);
    let Fragment = StyledTourElementMain;
    const dispatch = useAppDispatch();
    const isFavoritePage = window.location.pathname === PATH_FAVORITES;

    const handleClickLike = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { description, supplier, ...processData } = props.data; // Loại bỏ key description & supplier
        const tour = {
            ...processData,
            date: new Date(),
        };
        const method = isLiked ? FAVORITE_METHOD_UNLIKE : FAVORITE_METHOD_LIKE;
        dispatch(toggleFavorites(tour, method));
    };
    // console.log(description);

    if (width > 767 && pathname === PATH_SEARCH_RESULT) {
        Fragment = StyledTourElementSearch;
    }
    return (
        <StyledTourElement>
            <div
                className={`heart ${pathname === PATH_SEARCH_RESULT ? "searchResultPage" : ""}`}
                style={pathname.includes("favorites") ? { right: "15px" } : {}}
            >
                <HeartCommon active={isLiked} onClick={handleClickLike} />
            </div>
            <Fragment
                to={`/detail-tour/${props.data.productId}`}
                style={pathname.includes("favorites") ? { width: "unset", margin: 0 } : {}}
            >
                <div
                    className="tour_image"
                    style={{ backgroundImage: `url(${productImagePath})` }}
                ></div>
                <div className="main">
                    <div className="info">
                        <p className="destination__title">{name}</p>
                        <p className="destination__place">
                            <IconMapPin />
                            <span className="placeName">{location}</span>
                        </p>
                        {!isFavoritePage && !!description && !(typeof description === "object") && (
                            <div className="description">
                                <span>{parse(description)}</span>
                            </div>
                        )}
                        {!!supplier && !(typeof description === "object") && (
                            <div className="supplier">
                                Supplier <span>{sliceString(supplier, 4)}</span>
                            </div>
                        )}
                    </div>
                    <div className="durationNPrice">
                        <div className="destination__duration">
                            <IconClock />
                            <span className="time">{duration}</span>
                        </div>
                        <div className="destination__price">
                            <span className="form">From</span>
                            <span className="price">${Number(rrp).toFixed(2)}</span>
                        </div>
                    </div>
                </div>
            </Fragment>
        </StyledTourElement>
    );
});
