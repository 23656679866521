import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import reducer, { RootState } from './Slices';

// Define persist configuration
const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'auth',
        'signIn',
        'paymentStep',
        'favoriteTours',
        'externalData',
        'cart',
        'status',
        'savedCart',
        'booking',
        'tour',
        'agent',
    ],
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, reducer);

// Configure store
const store = configureStore({
    reducer: persistedReducer,
    // Add custom middleware (if needed) and include default middleware
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Optional: depending on your use case
        }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export default store;

// Custom hooks for useDispatch and useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
