import styled from "styled-components";
import parse from 'html-react-parser';

import { useWindowDimensions } from "@utilities";

interface IProps {
    listImg?: string[];
    title: string;
    subTitle: string;
}

export const WelcomeBlockCommon = (props: IProps) => {
    const { listImg, title, subTitle } = props;
    const { width } = useWindowDimensions();
    return (
        <Container width={width}>
            <div className="logo">
                <img src="/images/logos/FreelanceTravel_Primary_Logo_(1).svg" alt="" />
            </div>
            <div className="blockMain">
                <p className="title">{title}</p>
                <p className="content">{parse(subTitle)}</p>
                {listImg ? (
                    <div className="listImg">
                        {listImg.map((item, index) => {
                            return (
                                <div className="imgItem" key={index}>
                                    <img src={item} alt="" />
                                </div>
                            );
                        })}
                    </div>
                ) : <></>}
            </div>
        </Container>
    );
};

const Container = styled.div<{ width: number }>`
    background-color: #fff;
    padding: 62px 22px 0 22px;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: ${p => p.width < 767 ? `${p.width - 32}px` : "500px"};

    .logo {
        text-align: center;
    }

    .title {
        color: #000;
        font-size: 22px;
        font-weight: 500;
        line-height: 29px;
        text-align: center;
        margin-bottom: 12px;
    }

    .blockMain {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .listImg {
            display: flex;
            flex: 1;
            align-items: center;

            .imgItem {
                margin: 0 10px;
            }
        }
    }

    .content {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        color: ${p => p.theme.colors.gray_2};
    }
`;