import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { TotalPriceComponent } from "@components";
import { breakpoint } from "@utilities";
import { CartConstants } from "@constants";
import { AppDispatch, checkOptionalField, selectCart, useAppSelector } from "@redux";
import CartItem from "./CartItemModule";

interface IProps {
    moveTo: (step: number) => void;
    handleDeletePopUp: (cartDeleteId?: string | number) => void;
}

export const CartModule = (props: IProps) => {
    //prop
    const { moveTo, handleDeletePopUp } = props;
    //page hook
    const dispatch = useDispatch<AppDispatch>();
    //redux state
    const carts = useAppSelector(selectCart).carts;

    useEffect(() => {
        dispatch(checkOptionalField());
    }, [carts.length]);
    const handleSwitchToCheckout = () => {
        moveTo(CartConstants.CHECKOUT);
    };

    return (
        <Container>
            <div>
                <ListProducts>
                    {carts.map((item, index) => {
                        return (
                            <div key={index}>
                                <CartItem item={item} handleDeletePopUp={handleDeletePopUp} />
                            </div>
                        );
                    })}
                </ListProducts>
                <TotalPriceComponent
                    btnTitle="Checkout"
                    onBtnClick={handleSwitchToCheckout}
                    page="cart"
                />
            </div>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;

    ${breakpoint.breakOnlyMobile`
        flex: 1;
        display: flex;
        flex-direction: column;
    `}

    & > div {
        margin: 0 auto;

        ${breakpoint.breakOnlyMobile`
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
        `}
    }

    ${breakpoint.breakTablet`
        background-color: #F2F2F2;

        & > div {
            display: flex;
            width: ${(p) => p.theme.widths.wide_700};
        }
    `}

    ${breakpoint.breakIpadPro`
        & > div {
            width: ${(p) => p.theme.widths.wide_900};
        }
    `}
    
    ${breakpoint.breakLarge`
        & > div {
            width: ${(p) => p.theme.widths.wide_1110};
        }
    `}
`;

const ListProducts = styled.div`
    background-color: ${(props) => props.theme.colors.gray_6};
    padding: 16px 16px 0 16px;

    .heading {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 7px;
    }

    .infoWrapper {
        padding-bottom: 16px;
    }

    .MuiSelect-icon {
        color: ${(props) => props.theme.colors.main} !important;
    }

    .MuiSelect-select.MuiSelect-select {
        font-weight: 700 !important;
        font-size: 16px !important;
        width: 85px;
    }

    .MuiInput-underline:after {
        display: none !important;
    }

    .MuiInput-underline:before {
        border: none !important;
    }

    ${breakpoint.breakTablet`
        background-color: #F2F2F2;
        padding: 0 16px 44px 0;
        flex: 698;

        .infoWrapper {
            background-color: #F2F2F2;
        }

        .MuiInput-root {
            display: none;
        }
    `}

    ${breakpoint.breakIpadPro`
        padding: 0 33px 44px 0;
    `}
    
    ${breakpoint.breakLarge`
        padding: 0 62px 84px 0;
    `}
`;
