import { Container } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { stateLocation } from 'src/Interfaces/paymentFail';
import styled from 'styled-components';


const PaymentFail = () => {
    const location: stateLocation = useLocation();

  return (
    <Container>
    <FailOrder>
        <div className="orderFailBackground">
            <div className="orderFail">
                    <img className="svg" src="/images/fi_x.svg" alt="order_fail" />
            </div>
        </div>
        <p className="heading">Payment Failure</p>
        <div className="subHeading">
            <p>Payment attempt failed</p>
            <p>Try again with different payment method</p>
            <p style={{whiteSpace: "pre-line"}}>{location?.state?.message}</p>

        </div>
    </FailOrder>
</Container>
  );
};

const FailOrder = styled.div`
.orderFailBackground {
    background: linear-gradient(0deg, #FFFFFF -20.99%, rgba(255, 255, 255, 0.17) 119.75%), #F2F2F2;
    height: 78px;
    width: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;

    .orderFail {
        height: 60px;
        width: 60px;
        background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    
        .svg {
            width:70%;
            fill: white;
          }
    }
}
.heading {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-top: 11px;
    margin-bottom: 19px;
}

.subHeading {
    margin-bottom: 30px;
    font-size:12px;
    color:red;
    
    p{
        line-height: 25px
    }
}
`;

export default PaymentFail;